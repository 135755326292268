import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWrapper from "../../TestWrapper";

export default function DesktopWearing({ answer }) {
  const { t } = useTranslation();

  const questionText = t(
    "For the next section you will need a mobile device (tablet/smartphone)"
  );

  const buttonAction = () => {
    answer(null);
  };

  return (
    <TestWrapper image={`../images/test/009.jpg`}>
      <h2>{questionText}</h2>
      <div className="answerArea">
        <div className="nav-view">
          <div onClick={() => buttonAction()} className="nav-button">
            <div>{t("Begin!")}</div>
          </div>
        </div>
      </div>
    </TestWrapper>
  );
}
