import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWrapper from "../../TestWrapper";

export default function DesktopGender({ answer }) {
  const { t } = useTranslation();

  const questionText = t("What is your gender?");
  const answerText1 = t("Male");
  const GENDER_MALE = 1;
  const answerText2 = t("Female");
  const GENDER_FEMALE = 2;
  const answerText3 = t("Other");

  const buttonAction = (val) => {
    answer(val === GENDER_MALE ? "male" : "female");
  };

  return (
    <TestWrapper image={`../images/test/003.jpg`}>
      <h2>{questionText}</h2>

      <div className="multiple-buttons">
        <div
          onClick={() => buttonAction(GENDER_MALE)}
          className="button-default"
        >
          <div>{answerText1}</div>
        </div>
        <div
          onClick={() => buttonAction(GENDER_FEMALE)}
          className="button-default"
        >
          <div>{answerText2}</div>
        </div>
        <div
          onClick={() => buttonAction(GENDER_FEMALE)}
          className="button-default"
        >
          <div>{answerText3}</div>
        </div>
      </div>
    </TestWrapper>
  );
}
