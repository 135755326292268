import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles.css";
import { big_c } from "../../../sketches/big_c";
import { getSize2 } from "../../../utils/core";

export default function Desktop4Circles({ head, subhead, state }) {
  const { or, ratio, currentVaIdx } = state;
  const orientation = (or - 1) * 90;
  // const size = getSize(va, parseFloat(ratio));
  const size = getSize2(currentVaIdx, ratio);

  // TODO: improvement with cover(true, false) to return feedback to user?
  // cover = true draw full component "like" disabled status
  const cover = false;

  return (
    <div className="test-instructions-wrapper">
      <div className="test-instructions">
        <h2>{head}</h2>
        <h2>{subhead}</h2>
      </div>

      <div className="canvas-wrapper">
        <ReactP5Wrapper sketch={big_c} i={orientation} r={size} c={cover} />
      </div>
    </div>
  );
}
