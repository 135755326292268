import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWrapper from "../../TestWrapper";

export default function DesktopHaveSpecs({ answer }) {
  const { t } = useTranslation();

  const questionText = t("Do you have your old glasses with you?");
  const answerText1 = t("Yes");
  const answerText2 = t("No");

  const buttonAction = (val) => {
    const haveSpecs = val === answerText1 ? "yes" : "no";
    answer({ haveSpecs });
  };

  return (
    <TestWrapper image={`../images/test/010.jpg`}>
      <h2>{questionText}</h2>

      <div className="multiple-buttons">
        <div
          onClick={() => {
            buttonAction(answerText1);
          }}
          className="button-default"
        >
          {answerText1}
        </div>

        <div
          onClick={() => {
            buttonAction(answerText2);
          }}
          className="button-default"
        >
          <div>{answerText2}</div>
        </div>
      </div>
    </TestWrapper>
  );
}
