import { useTranslation } from "react-i18next";
import axios from "axios";
import { HashLink } from "react-router-hash-link";
import { useState, useEffect } from "react";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import { useHistory, useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Logo from "../assets/images/logo.png";
import { useNewTest } from "../hooks/useNewTest";
import { isMobile } from "../utils";

export default function Header() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState("loading");
  const [statusResend, setStatusResend] = useState("done");
  const { create } = useNewTest();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const getActiveClass = (lang) => {
    if (lang === i18n.language) {
      return "active";
    }
    return "";
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/user/get-user`, {
        withCredentials: true,
      })
      .then(function (res) {
        if (res?.data?.result && res?.data?.user) {
          setUser(res.data.user);
        }
        setStatus("done");
      })
      .catch((e) => {
        setStatus("done");
      });
  }, []);

  const reSendEmailValidation = async () => {
    setStatusResend("loading");
    await axios
      .post(
        `${process.env.REACT_APP_API}/user/resendvalidation`,
        {
          user,
          text_reset_first: t("text_validation_first"),
          text_reset_second: t("text_validation_second"),
          text_reset_footer: t("text_validation_footer"),
          text_reset_subject: t("text_validation_subject"),
          text_reset_button: t("text_validation_button"),
        },
        {
          withCredentials: true,
        }
      )
      .then(function (res) {
        Swal.fire({
          icon: "success",
          title: t("Email sent"),
          text: t(
            "Check your email to verify your account, and don’t forget to check your spam folder."
          ),
        });
        setStatusResend("done");
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("Error resending the confirmation email."),
        });
        setStatusResend("done");
      });
  };

  const logout = async (e) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_API}/user/logout`, {
        withCredentials: true,
      })
      .then(function (res) {
        if (res?.data?.result) {
          window.location.href = "/";
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Could not logout at this time. Try again later.",
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createAndRedirectTo = async () => {
    if (isMobile()) {
      alert(t("You need to start with a PC/Mac browser, not mobile"));
      return;
    }

    const test = await create();
    history.push({
      pathname: "/test/connection",
      search: "?token=" + test.token,
    });
  };

  return (
    <header>
      <div className="content">
        <HashLink className="logo" to="/home">
          <img src={Logo} alt="" />
        </HashLink>

        <nav>
          {user && user.email_valid && (
            <Link to="#" onClick={createAndRedirectTo}>
              {t("Get Started")}
            </Link>
          )}
          <HashLink to="/home">{t("Home")}</HashLink>
          <HashLink to="/home#about">{t("About")}</HashLink>
          <HashLink to="/home#faq">{t("FAQ")}</HashLink>
          {user && !user.subscription.subscription_id && (
            <Link to="/subscription-trial">{t("Try for free")}</Link>
          )}
        </nav>

        <div className="user">
          {status === "done" ? (
            <>
              {user ? (
                <div className="user-drop">
                  <div className="icon">
                    <UserIcon />
                  </div>
                  <div className="sub-menu">
                    <Link to="/profile">{t("My Profile")}</Link>
                    <Link to="/tests">{t("My Tests")}</Link>
                    <Link to="/subscription">{t("My Subscription")}</Link>
                    <a href="#sair" onClick={logout}>
                      {t("Logout")}
                    </a>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    style={{ marginRight: "10px" }}
                    className="login-button"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/signup", {
                        from: location.pathname,
                      });
                    }}
                  >
                    {t("Sign Up")}
                  </button>
                  <button
                    className="login-button"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/login", {
                        from: location.pathname,
                      });
                    }}
                  >
                    Login
                  </button>
                </div>
              )}
            </>
          ) : null}
          {status === "loading" && <div className="skelleton"></div>}
        </div>

        <div className="languages">
          <button
            className={getActiveClass("en")}
            onClick={() => changeLanguage("en")}
          >
            EN
          </button>
          <button
            className={getActiveClass("pt-BR")}
            onClick={() => changeLanguage("pt-BR")}
          >
            PT
          </button>
          <button
            className={getActiveClass("es")}
            onClick={() => changeLanguage("es")}
          >
            ES
          </button>
          <button
            className={getActiveClass("it")}
            onClick={() => changeLanguage("it")}
          >
            IT
          </button>
        </div>
      </div>
      {user && !user.email_valid && (
        <div className="validate-email-msg">
          <p>
            {t(
              "You need to verify your email to continue. If you haven’t received the email,"
            )}{" "}
            {statusResend === "done" ? (
              <a href="#reenviar" onClick={() => reSendEmailValidation()}>
                {t("click here to resend it")}
              </a>
            ) : (
              <span style={{ color: "#007bff" }}>{t("Sending...")}</span>
            )}
          </p>
        </div>
      )}
    </header>
  );
}
