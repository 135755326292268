import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import cartoonCoverLeftEyeGlasses from "../../../assets/img/cartoonCoverLeftEyeGlasses.svg";

export default function DesktopCoverLeftGlassON() {
  const { t } = useTranslation();

  let head = t("Stage Complete! ");
  head = head + t("Please wear your glasses for the next test.");

  return (
    <TestWaitScreen
      image={cartoonCoverLeftEyeGlasses}
      head={head}
      subhead={t("Cover your LEFT eye then press 'Next Stage'")}
    />
  );
}
