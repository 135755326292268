import { useTranslation } from "react-i18next";
import TestWrapper from "../components/TestWrapper";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSpecInfoOptions } from "../hooks/useSpecInfoOptions";

export const SpecsInfo = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const [visionQuality, setVisionQuality] = useState('')
  const [lensCondition, setLensCondition] = useState('')
  const [lastExam, setLastExam] = useState('')

  const visionQualityOptions = ['Excellent', 'Good', 'Bad']
  const lensConditionOptions = ['lenses with damage', 'lenses without damage']
  const lastExamOptions = ['Less than 6 months', '6 months to 1 year', '1 to 3 years', 'More than 3 years']

  const SaveSpecs = () => {
    if (visionQuality && lensCondition && lastExam !== '') {

      localStorage.setItem("VisionQuality", visionQuality)
      localStorage.setItem("LensCondition", lensCondition)
      localStorage.setItem("LastExam", lastExam)

      history.push({
        pathname: '/haveglasses',
      });
    } else {
      alert(t('specs_info_form.warning_alert'))
    }
  }

  return (<TestWrapper image={`images/test/001.jpg`}>

    <section className="specs-info-wrapper">
      <h2>{t('specs_info_form.title')}</h2>

      <article>
        <h4 >{t('specs_info_form.questions.vision_quality')}</h4>
        <div className="specs-info-options">
          {useSpecInfoOptions(visionQualityOptions, 'visionQuality', setVisionQuality)}
        </div>
      </article>

      <article>
        <h4 >{t('specs_info_form.questions.lens_condition')}</h4>
        <div className="specs-info-options">
          {useSpecInfoOptions(lensConditionOptions, 'lensCondition', setLensCondition)}
        </div>
      </article>

      <article>
        <h4>{t('specs_info_form.questions.last_exam')}</h4>
        <div>
          {useSpecInfoOptions(lastExamOptions, 'lastExam', setLastExam)}
        </div>
      </article>

      <div className="nav-view">
        <div
          onClick={SaveSpecs}
          className="nav-button"
        >
          <div>{t('Continue')}</div>
        </div>
      </div>

    </section>


  </TestWrapper>)
}