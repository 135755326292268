import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles.css";
import { getSize } from "../../../utils/core";
import { INITVA, Q3_ORIENTATIONS } from "../../../utils/constants";
import { axis } from "../../../sketches/axis_2";

export default function DesktopRectangle({ head, subhead, state }) {
  const { or, ratio } = state;

  const orientation = Q3_ORIENTATIONS[or];
  const size = getSize(INITVA - ratio, 67 + parseFloat(ratio));

  return (
    <div className="test-instructions-wrapper">
      <div className="test-instructions">
        <h2>{head}</h2>
        <h2>{subhead}</h2>
      </div>

      <div className="canvas-wrapper">
        <ReactP5Wrapper sketch={axis} i={orientation} r={size} c={false} />
      </div>
    </div>
  );
}
