import { withRouter } from "react-router-dom";
import React, { useEffect } from "react";
import qs from "query-string";
import nearFar from "../assets/img/nearfar.jpg";
import "./styles.css";
import { getQuery } from "../utils";
import { useTranslation } from 'react-i18next';
import { useRealtime } from "../hooks/useRealtime";

function Mpage14Test1B(props) {
    const { t } = useTranslation();
	const realtime = useRealtime("mobile");
	const query = getQuery();

	useEffect(() => {
		realtime.trigger(query.token, 'update-message', { action: 'updateStatus', data: { pageStatus: 4, token: query.token }});
		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	const Button1 = (val) => {
		if (val == 1) {
			localStorage.setItem("Answer1b", "near");
		} else if (val == 2) {
			localStorage.setItem("Answer1b", "far");
		} else {
			localStorage.setItem("Answer1b", "not sure");
		}

		realtime.trigger(query.token, 'update-message', { action: 'sendAnswer', data: { answer: val, token: query.token }});

		const searchString = qs.stringify(query);
		props.history.replace({
			pathname: "/mtests",
			search: searchString,
		});
		props.history.push({
			pathname: "/mtests",
			search: searchString,
		});
	};

	return (
		<>
			<div style={{maxHeight: "90vh", overflow: "hidden"}}>
				<div className="mobile-view">
					<h1>{t("On which device is the picture easier for you to see?")}</h1>
					<div className="image-view">
						<img src={nearFar} alt="" style={{ width: "90%" }} />
					</div>
					<div className="nav-buttons">
						<div className="nav-button" onClick={() => Button1(1)}>
							{t("Mobile")}
						</div>
						<div className="nav-button" onClick={() => Button1(2)}>
							{t("Computer")}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default withRouter(Mpage14Test1B);
