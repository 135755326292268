import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWrapper from "../../TestWrapper";
import { calculateAxisRatio } from "../DynamicQuestion.script";

export default function DesktopKnowSpecs({ answer }) {
  const { t } = useTranslation();

  const questionText = t("Do you know your current glasses prescription?");
  const questionText2 = t(
    "(your glasses prescription will help us to better understand your test results)"
  );
  const answerText1 = t("Yes");
  const answerText2 = t("No");

  const buttonAction = (val) => {
    const ratio = calculateAxisRatio();
    const knowSpecs = val === answerText1 ? "yes" : "no";

    answer({ knowSpecs, ratio });
  };

  return (
    <TestWrapper image={`../images/test/004.jpg`}>
      <h2>{questionText}</h2>
      <h4>{questionText2}</h4>

      <div className="multiple-buttons">
        <div
          onClick={() => {
            buttonAction(answerText1);
          }}
          className="button-default"
        >
          {answerText1}
        </div>

        <div
          onClick={() => {
            buttonAction(answerText2);
          }}
          className="button-default"
        >
          <div>{answerText2}</div>
        </div>
      </div>
    </TestWrapper>
  );
}
