"use client";
import { useRef, useEffect } from 'react';

export default function AudioGuide({lang, sound}) {
  const audioRef = useRef(null);

  useEffect(() => {
    playAudio();
  }, []);

  const langFilter = {
    'en-US': 'en',
    'en-GB': 'en',
    'en-CA': 'en',
    'en-AU': 'en',
    'pt-BR': 'pt-BR',
    'es': 'es',
    'it': 'it',
  }
  
  const playAudio = async () => {
    try {
      if (audioRef.current) {
        audioRef.current.muted = false;
        audioRef.current.currentTime = 0;
        await audioRef.current.play();
      }
    } catch (error) {
      console.error('Erro ao reproduzir áudio:', error);
    }
  };

  return (
    <>
      <audio
        ref={audioRef}
        src={`/sounds/${sound}-${langFilter[lang] || 'en'}.mp3`}
        preload="auto"
        style={{display: "none"}}
      />
    </>
  )
}