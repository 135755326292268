import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import cartoonCoverRightEyeGlasses from "../../../assets/img/cartoonCoverRightEyeGlasses.svg";

export default function DesktopCoverRightGlassON() {
  const { t } = useTranslation();

  let head = t("Stage Complete! ");
  head = head + t("Please wear your glasses for the next test.");

  return (
    <TestWaitScreen
      image={cartoonCoverRightEyeGlasses}
      head={head}
      subhead={t("Cover your RIGHT eye then press 'Next Stage'")}
    />
  );
}
