import React, { useEffect } from "react";
import qs from "query-string";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { useRealtime } from "../hooks/useRealtime";
import { getQuery } from "../utils";

function MPagePlaceObject(props) {
     const { t } = useTranslation();
	 const realtime = useRealtime("mobile");

	useEffect(() => {
		let query = getQuery();
		realtime.trigger(query.token, 'update-message', { action: 'updateStatus', data: { pageStatus: 2, token: query.token }});
		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	const query = qs.parse(window.location.search);
	const Button1 = () => {
		const searchString = qs.stringify(query);
		props.history.replace({
			pathname: "/mtest1A",
			search: searchString,
		});
		props.history.push({
			pathname: "/mtest1A",
			search: searchString,
		});
	};
	return (
		<>
			<div style={{maxHeight: "90vh", overflow: "hidden"}}>
				<div className="mobile-view">
					<h1>{t("Place an object at your toes to mark your spot")}</h1>
					<h2>
						{t("Confirm that you are now 10 feet from your computer screen with your mobile device")}
					</h2>
					<div className="nav-view">
						<div className="nav-button" onClick={Button1}>
							{t("Confirm")}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default withRouter(MPagePlaceObject);
