import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import cartoon1 from "../../../assets/img/cartoon1.svg";

export default function DesktopCountFeet({ state }) {
  const { t } = useTranslation();
  const { countFeet } = state;

  return (
    <TestWaitScreen
      sound="mpage12"
      image={cartoon1}
      subhead={`${t("Take")} ${countFeet} ${t(
        "heel-to-toe steps (10ft) away from your computer screen"
      )}`}
    />
  );
}
