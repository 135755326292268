import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWrapper from "../../TestWrapper";

export default function DesktopAgree({ answer }) {
  const { t } = useTranslation();

  return (
    <TestWrapper image={`../images/test/001.jpg`}>
      <div className="agree-view">
        <h2>
          {t(
            "Do you understand that this online test is not a replacement for an eye exam?"
          )}
        </h2>

        <div className="nav-view">
          <div onClick={() => answer(true)} className="nav-button">
            <div>{t("Yes")}</div>
          </div>
        </div>
      </div>
    </TestWrapper>
  );
}
