import "../styles.css";
import { useTranslation } from "react-i18next";
import MobileCoverEye from "./MobileCoverEye";
import cartoonCoverRightEyeGlasses from "../../../assets/img/cartoonCoverRightEyeGlasses.svg";

export default function MobileCoverRightGlassON({ answer }) {
  const { t } = useTranslation();

  let head = t("Stage Complete! ");
  head = head + t("Please wear your glasses for the next test.");

  return (
    <MobileCoverEye
      image={cartoonCoverRightEyeGlasses}
      head={head}
      subhead={t("Cover your RIGHT eye then press 'Next Stage'")}
      answer={answer}
    />
  );
}
