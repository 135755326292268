import "../styles.css";
import { useTranslation } from "react-i18next";
import Desktop2Circles from "./Desktop2Circles";

export default function Desktop2CirclesRight({ test, state }) {
  const { t } = useTranslation();

  return (
    <Desktop2Circles
      head={t("Glasses OFF")}
      subhead={t("Cover your LEFT eye")}
      test={test}
      state={state}
    />
  );
}
