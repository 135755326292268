import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import { useEffect } from "react";

export default function MobileConnection({ test, answer }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (test && test.workflow === "connection") {
      // at the moment, trigger answer to move to next step, we can edit this page and confirm with the user if necessary
      answer({});
    }
  });

  return (
    <TestWaitScreen subhead={t("Waiting for action on desktop device...")} />
  );
}
