import axios from "axios";
import { URL_ROUTE_USER } from "../utils/constants";

const defaultTestParams  = { 
  pageStatus: 0,
  axis: 0,
  flag: 0,
  answer: 0,
  infos: {}
};

const processMobileAction = {
  updateStatus: (test, data) => {
    let updateStatusUrl = URL_ROUTE_USER + `/updateStatus?token=${data.token}&pageStatus=${data.pageStatus}`;
    test.flag = 0;
    test.pageStatus = data.pageStatus;
    if (data.axis !== undefined) {
      test.axis = data.axis;
      updateStatusUrl += `&axis=${data.axis}`;
    }
    // storage too on backend
    fetch(updateStatusUrl);
  },
  sendAnswer: (test, data) => {
    let flag = 1;
	  if (test.flag !== 0) {
		  test.flag = 0;
    } else if (test.pageStatus < 5) {
		  flag = 2;
	  }

    test.answer = data.answer;
    test.flag = flag;
    
    // storage too on backend
    fetch(URL_ROUTE_USER + "/sendAnswer" + window.location.search + `&answer=${data.answer}`)
  },
  prepareToAnswer: (test, data) => {
    test.infos = Object.assign(test.infos, data.infos);
    test.answer = 9;
    test.flag = 1;
  },
  openQRCode: (test, data) => {
    if (test.pageStatus > 1) {
      return;
    }
    processMobileAction.updateStatus(test, data);
  }
}

const processDesktopAction = {
  updateInfoStatus: (test, data) => {
    test.infos = Object.assign(test.infos, data.infos);
  },
  updateState: (test, data) => {
    test.infos = Object.assign(test.infos, data.infos);
    test.flag = 0;
  },
  updateTestRecord: (test, data) => {
    const testLogKey = `test-log-${test.token}`;
    let pageStatus = test.pageStatus;
    let left = test.infos.left || false;

    let testLog = localStorage.getItem(testLogKey);
    if (testLog) {
      testLog = JSON.parse(testLog);
    } else {
      testLog = [];
    }

    if (!testLog.length) {
      // new record, start with 3
      pageStatus = 3;
    } else {
      // if current record is result and before answer was desktop, update page and left info
      const lastRecord = testLog[testLog.length-1];
      if (data.type === 'results' && lastRecord['results']) {
        // if last answer is results too, increment page 
        pageStatus = lastRecord.page + 1;
      } else if (data.type === 'results' && lastRecord['answerDesktop']) {
        // answer the last record, fiz pageStatus and left info
        pageStatus = lastRecord.page;
        left = lastRecord['answerDesktop'].left;
      }
    }

    // save base records info
    const record = {
      page: pageStatus,
      left: left,
      answer: test.answer,
      flag: test.flag,
      axis: test.axis,
      infos: test.infos
    };

    record[data.type] = data.value;

    testLog.push(record);

    localStorage.setItem(testLogKey, JSON.stringify(testLog))

    axios.post(
      `${URL_ROUTE_USER}/intake?token=${test.token}`,
      { record: record },
      { headers: {"Content-type": "application/json; charset=UTF-8"} }
    );
  }
}

const storageTest = (token, data) => {
  localStorage.setItem(`test-${token}`, JSON.stringify(data));
}

const create = async () => {
  const resp = await axios.get(`${URL_ROUTE_USER}/create`, { withCredentials: true });
  const token = resp.data.token;
  const test = {
    token,
    uuid: resp.data.uuid,
    ...defaultTestParams 
  };
  storageTest(token, test);
  return test;
}

const get = async (token) => {
  let test = localStorage.getItem(`test-${token}`);

  if (!test) {
    // test not found, get info on the backend
    const resp = await axios.get(`${URL_ROUTE_USER}/getAnswer?token=${token}`, { withCredentials: true });
    storageTest(resp.resp.token, resp.data);
    return resp.data;
  }

  return JSON.parse(test);
}

const getWithRefreshMode = async (token) => {
  const test = await get(token);

  if (test.infos.openAnswer) {
    test.answer = 9;
    test.flag = 1;
  } else if (test.flag === 0) {
		test.answer = 0;
	}

  return test;
}

// clone backend endpoints to validate client/client communication
// { action: 'updateInfoStatus', data: {...currentMobileParams }}
const processMessage = async (message, mobile) => {
  const test = await get(message.data.token);
  mobile
    ? processMobileAction[message.action](test, message.data)
    : processDesktopAction[message.action](test, message.data);
  storageTest(message.data.token, test);
  return test;
}

const processMobileMessage = async (message, callback) => {
  const test = await processMessage(message, true);
  return callback(test);
}

const processDesktopMessage = async (message, callback) => {
  const test = await processMessage(message, false);
  return callback(test);
}

const openDynamicAnswers = async(token) => {
  const test = await get(token);
  if (test.pageStatus === 4) {
    // first time open the answer... move to page 5
    test.pageStatus = 5;
    test.flag = 0;
  }
  storageTest(token, test);
  return test;
}

export const useTest = () => {
  return {
    create,
    get,
    getWithRefreshMode,
    processMobileMessage,
    processDesktopMessage,
    openDynamicAnswers
  }
};