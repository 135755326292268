// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { withRouter } from "react-router-dom";
import React from "react";
import qs from "query-string";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import "./styles.css";
import { withTranslation, useTranslation } from "react-i18next";
import {
	PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK,
	PAGE_STATUS_PLACING_OBJECT,
	PAGE_STATUS_PLACING_TEST1A,
	PAGE_STATUS_PLACING_TEST1B,
	PAGE_STATUS_PLACING_TEST2,
	PAGE_STATUS_PLACING_TEST3,
	PAGE_STATUS_PLACING_TEST4,
	URL_ROUTE_USER,
	Q_ARR,
	Q3_ORIENTATIONS,
	INITVA,
	CHANGE_INTS,
	VA_MULTIPLIER,
	MAX_AXIS_VA,
	QR_DOMAIN,
} from "../utils/constants";
import { getQuery } from "../utils";
import nearFar from "../assets/img/nearfar.jpg";
import { ReactP5Wrapper } from "react-p5-wrapper";
import { big_c } from "../sketches/big_c";
import { axis } from "../sketches/axis_2";
import {
	handleQuestion,
	getRandomInt,
	getSize,
	getAngle,
	getOrientation,
	getSight,
} from "../utils/core";
import cartoonOne from "../assets/img/cartoon1.svg";
import cartoonTwo from "../assets/img/cartoon2.svg";
import cartoonBlank from "../assets/img/cartoonBLANK.jpeg";
import cartoonLeft from "../assets/img/cartoonCoverLeftEye.svg";
import cartoonRight from "../assets/img/cartoonCoverRightEye.svg";
import cartoonLeftGlass from "../assets/img/cartoonCoverLeftEyeGlasses.svg";
import cartoonRightGlass from "../assets/img/cartoonCoverRightEyeGlasses.svg";
import { useRealtime } from '../hooks/useRealtime';
import { useTest } from "../hooks/useTest";
import AudioGuide from "../components/AudioGuide";
import { QRCode } from "react-qr-svg";

class Page12StartSession extends React.Component {
	state = {
		head: this.props.t("Stage Complete!"),
		subhead: this.props.t("Cover your LEFT eye then press 'Next Stage'"),
		nextTest: true,
		pageStatus: 0,
		answer: 0,
		flag: 0,
		cover: false,
		q_idx: 0,
		wearing: false,
		scR: localStorage.getItem("ratio"),

		// Intermediate variables
		or: getRandomInt(4),
		va: INITVA,
		changeInt: CHANGE_INTS[0],
		runCount: 0,
		wrongCount: 0,
		left: false,
		axis_x: 0,
		axis_y: 0,
		finalized: false,
		modalQrcode: false,
		token: ""
	};

	async updateTestRecord(token, data, type = "answerDesktop") {
		if (this.state.finalized === true) return;
		// if (this.state.finalized === true) return;
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { processDesktopMessage } = useTest();
		await processDesktopMessage({ action: 'updateTestRecord', data: { token, type, value: data } }, () => {});
	}

	async updateInfoStatus(token, { infos }) {
		if (this.state.finalized === true) return;
		const updateTest = async (token, infos) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const { processDesktopMessage } = useTest();
			await processDesktopMessage({action: 'updateInfoStatus', data: { token, infos }}, () => {
				axios.post(
					`${URL_ROUTE_USER}/updateInfoStatus?token=${token}`,
					{ infos },
					{ headers: {"Content-type": "application/json; charset=UTF-8"} }
				);
			});
		}
		await updateTest(token, infos);	
	}

	loadScreen(token) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { getWithRefreshMode, processMobileMessage, openDynamicAnswers } = useTest();
		const getTest = async (token) => {
			const test = await getWithRefreshMode(token);
			this.setState({ token });

			// eslint-disable-next-line react-hooks/rules-of-hooks
			const realtime = useRealtime("desktop");
			realtime.subscribe(token, async () => {
				realtime.listener(token, 'update-message', async (message) => {
					await processMobileMessage(message, async (data) => {
						// if receive openQRCode action, sent to event to mobile redirect to correct route
						if (message.action === 'openQRCode') {
							realtime.trigger(data.token, 'm-connection-opened', data);
						}												

						await this.pollingStatus(data);
					});
				});
				realtime.listener(token, 'open-dynamic-answers', async (data) => {
					const test = await openDynamicAnswers(data.token);

					realtime.trigger(data.token, 'm-open-dynamic-answers', test);
					await this.pollingStatus(test);
				});
				realtime.addedDevice(token, (data) => {
					this.setState({ modalQrcode: false });
				});
				realtime.removedDevice(token, (data) => {
					if (data.info.type === "mobile") 
						this.setState({ modalQrcode: true });
				});
				await this.pollingStatus(test);
			});
		}
		getTest(token);
	}

	async updateState(token, state) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { processDesktopMessage } = useTest();
		await processDesktopMessage({action: 'updateState', data: { token, infos: state }}, () => {
			axios.post(
				`${URL_ROUTE_USER}/updateState?token=${token}`,
				{ ...state },
				{ headers: {"Content-type": "application/json; charset=UTF-8"} }
			);
		});
	}

	async completeTest(token, valueQARR) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { processDesktopMessage } = useTest();
		await processDesktopMessage({action: 'updateInfoStatus', data: { token, infos: { finalized: true } }}, async (data) => {
			await this.updateTestRecord(token, valueQARR, "results");
			axios.post(
				`${URL_ROUTE_USER}/complete?token=${token}`,
				{},
				{ 
					withCredentials: true,
					headers: {"Content-type": "application/json; charset=UTF-8"} 
				}
			);
		});
	}

	async updateStatus(token, pageStatus, axis) {
		const update = async (token, pageStatus, axis) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const { processMobileMessage } = useTest();
			await processMobileMessage({action: 'updateStatus', data: { token, pageStatus, axis }}, () => {});
		}
		await update(token, pageStatus, axis);
	}

	answerProcessed(token) {
		const sendMessage = async(token) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const { get } = useTest();
			const test = await get(token);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const realtime = useRealtime();
			realtime.trigger(token, 'm-update-message', test);

			if (test.infos?.finalized) {
				this.props.history.push({ pathname: "/thankyou", search: "?token=" + token });
			} else {
				await this.pollingStatus(test);
			}
		}
		sendMessage(token);
	}

	async pollingStatus(data) {
		let { pageStatus, flag, answer, uuid, token, infos: { q_idx = 0, runCount: currentRunCount = 0, left = false, openAnswer = false } = {} } = data;

		if (this.state.pageStatus === pageStatus 
			&& this.state.flag === flag) {
			return;
		}

		if (this.state.pageStatus !== pageStatus 
			&& this.state.flag === flag && flag !== 0) {
			return;
		}

		// let subhead = this.props.t("Cover your ");
		let subhead = "Cover your ";
		if (!left) {
			subhead = subhead + "LEFT";
		} else {
			subhead = subhead + "RIGHT";
		}
		subhead = this.props.t(subhead + " eye then press 'Next Stage'");

		this.setState({ pageStatus, flag, answer, uuid, q_idx, left, subhead, runCount: currentRunCount });
		localStorage.setItem("log_uuid", uuid);

		if (flag == 1) {
			// New answer from mobile
			this.setState({ cover: true });

			if (answer == 9) {
				// Refresh on mobile
				if (this.state.nextTest) {
					if (this.state.q_idx >= 7) {
						let head = this.props.t("Stage Complete! ");
						head =
							head +
							this.props.t("Please wear your glasses for the next test.");
						
						this.setState({ head });
					}

					let axis_idx = this.state.or;
					if (pageStatus === 6 && openAnswer) {
						axis_idx = 0; // reset or to restart with correct axis_idx
					}

					this.setState({ nextTest: false, or: axis_idx });
					await this.updateState(token, { axis_idx });
				}
			} else if (!this.state.nextTest && pageStatus >= 5) {
				let {
					or,
					va,
					runCount,
					wrongCount,
					changeInt,
					axis_x,
					axis_y,
					nextTest,
					answerCorrect,
				} = handleQuestion(
					pageStatus,
					answer,
					this.state.or,
					this.state.va,
					this.state.runCount,
					this.state.wrongCount,
					this.state.changeInt,
					this.state.axis_x,
					this.state.axis_y
				);
				console.table({
					q_idx,
					pageStatus,
					answer,
					or,
					va,
					runCount,
					wrongCount,
					changeInt,
					axis_x,
					axis_y,
					left,
					nextTest,
					answerCorrect
				});

				await this.updateTestRecord(token, {
					q_idx,
					pageStatus,
					answer,
					or,
					va,
					runCount,
					wrongCount,
					changeInt,
					axis_x,
					axis_y,
					left,
					nextTest,
					answerCorrect,
					state: this.state
				});

				const changePage = runCount !== this.state.runCount;
				await this.updateInfoStatus(token, {
					infos: {
						pageStatus,
						changePage: changePage,
						left: changePage ? !left : left,
						openAnswer: changePage ? false : true,
						nextTest,
						q_idx
					}
				});

				if (changePage) {
					left = !left;
					// Next Test
					if (nextTest) {
						// Salvar no recoords o resultado da resposta do teste
						const valueQARR = {};
						valueQARR["finalizedAt"] = new Date();

						let axis = 0;
						if (pageStatus == 5) {
							valueQARR["test"] = "test2";
							localStorage.setItem(Q_ARR[q_idx], va);
							valueQARR["value"] = { name: Q_ARR[q_idx], value: va };
							if (this.state.wearing) {
								pageStatus = 7;
								nextTest = false; // End of test with glasses
								this.props.history.push({ pathname: "/thankyou" });
							} else {
								va = localStorage.getItem(Q_ARR[2]) * VA_MULTIPLIER;
								if (va > MAX_AXIS_VA) {
									va = MAX_AXIS_VA;
								}
							}
						} else if (pageStatus == 6) {
							// localStorage.setItem(Q_ARR[q_idx], 175)// HARD CODE RIGHT Axis getAngle(axis_x, axis_y, wrongCount))
							valueQARR["test"] = "test3";
							localStorage.setItem(
								Q_ARR[q_idx],
								getAngle(axis_x, axis_y, wrongCount)
							);
							valueQARR["value"] = { name: Q_ARR[q_idx], value: getAngle(axis_x, axis_y, wrongCount) };
							localStorage.setItem(
								Q_ARR[q_idx] + "_percent",
								(Q3_ORIENTATIONS.length - wrongCount) /
									Q3_ORIENTATIONS.length
							);
							valueQARR["value"] = { name: Q_ARR[q_idx], value: (Q3_ORIENTATIONS.length - wrongCount) / Q3_ORIENTATIONS.length };
							axis_x = axis_y = wrongCount = 0;
							va = INITVA;
							axis = localStorage.getItem(Q_ARR[4]);
						} else if (pageStatus == 7) {
							valueQARR["test"] = "test4";
							localStorage.setItem(Q_ARR[q_idx], va);
							valueQARR["value"] = { name: Q_ARR[q_idx], value: va };

							await this.updateTestRecord(token, valueQARR, "results");

							if (
								!this.state.wearing &&
								localStorage.getItem("HaveSpec") == "yes"
							) {
								// Test corrected
								this.setState({ wearing: true });
								pageStatus = 4;
								or = getRandomInt(4);
								va = INITVA;
							} else {
								this.setState({ finalized: true });
								nextTest = false; // End of test without glasses
								await this.completeTest(token, valueQARR);
							}
						}

						await this.updateTestRecord(token, valueQARR, "results");

						if (this.state.finalized === false) {
							console.log('token finalizado', token);
							await this.updateStatus(token, pageStatus + 1, axis);
						}
						// Next eye
					} else {
						// Quando finaliza um teste cai nessa etapa
						// Fazer a chamada para gravar as informações que foram geradas no final de cada teste aqui.!!!!!!!
						const valueQARR = {};
						nextTest = true;
						if (pageStatus == 5) {
							valueQARR["test"] = "test2";
							localStorage.setItem(Q_ARR[q_idx], va);
							valueQARR["value"] = { name: Q_ARR[q_idx], value: va };
							va = INITVA;
						} else if (pageStatus == 6) {
							valueQARR["test"] = "test3";
							// localStorage.setItem(Q_ARR[q_idx], 90)// HARD CODE LEFT EYE AXIS getAngle(axis_x, axis_y, wrongCount))
							localStorage.setItem(
								Q_ARR[q_idx],
								getAngle(axis_x, axis_y, wrongCount)
							);
							valueQARR["value"] = { name: Q_ARR[q_idx], value: getAngle(axis_x, axis_y, wrongCount) };
							localStorage.setItem(
								Q_ARR[q_idx] + "_percent",
								(Q3_ORIENTATIONS.length - wrongCount) /
									Q3_ORIENTATIONS.length
							);
							valueQARR["value"] = { name: Q_ARR[q_idx], value: (Q3_ORIENTATIONS.length - wrongCount) / Q3_ORIENTATIONS.length };
							axis_x = axis_y = wrongCount = 0;
							va = localStorage.getItem(Q_ARR[3]) * VA_MULTIPLIER;
							if (va > MAX_AXIS_VA) {
								va = MAX_AXIS_VA;
							}
						} else if (pageStatus == 7) {
							valueQARR["test"] = "test4";
							// Refresh right eye axis on mobile
							await this.updateStatus(token, pageStatus, localStorage.getItem(Q_ARR[5]));
							// .then(() => { this.setState({nextTest: true})})
							localStorage.setItem(Q_ARR[q_idx], va);
							valueQARR["value"] = { name: Q_ARR[q_idx], value: va };
							va = INITVA;
						}

						await this.updateTestRecord(token, valueQARR, "results");
					}
					q_idx = q_idx + 1;
				}
				this.setState({
					or,
					va,
					runCount,
					wrongCount,
					changeInt,
					nextTest,
					axis_x,
					axis_y,
					left,
					q_idx,
				});
				await this.updateState(token, { q_idx, runCount, left, axis_idx: or });
			} else {
				await this.updateState(token, { q_idx });
			}
			this.answerProcessed(token);
		} else if (flag == 2) {
			let q_idx = this.state.q_idx;
			localStorage.setItem(Q_ARR[q_idx], getSight(answer));
			const valueQARR = {};

			valueQARR["value"] = { name: Q_ARR[q_idx], value: getSight(answer) };
			await this.updateTestRecord(token, valueQARR, "results");
			q_idx = q_idx + 1;
			this.setState({ q_idx, cover: true });
			await this.updateState(token, { q_idx });
			this.answerProcessed(token);
		} else {
			this.setState({ cover: false });
		}
	}

	componentDidMount() {
		let query = getQuery();
		this.loadScreen(query.token);
	}

	jump() {
		const query = qs.parse(window.location.search);
		// const searchString = qs.stringify(query);
		this.props.history.push({
			pathname: "/test1B",
			search: "?session=" + query.session,
		});
	}

	render() {
		return (
			<>
				<Modal 
					isOpen={this.state.modalQrcode} 
					onClose={() => this.setState({ modalQrcode: false })}
					token={this.state.token}
				>
						
				</Modal>
				{this.state.pageStatus ==
					PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK && (
					<WaitScreen
						left={this.state.left}
						q_idx={this.state.q_idx}
						pageStatus={this.state.pageStatus}
						head=""
						subhead={`${this.props.t("Take")} ${localStorage.getItem(
							"NumberOfSteps"
						)} ${this.props.t(
							"heel-to-toe steps (10ft) away from your computer screen"
						)}`}
					/>
				)}
				{(this.state.pageStatus == PAGE_STATUS_PLACING_OBJECT) && (
					<WaitScreen
						left={this.state.left}
						q_idx={this.state.q_idx}
						pageStatus={this.state.pageStatus}
						head={this.props.t("Test in process")}
						subhead={this.props.t("Waiting for action on mobile device...")}
					/>
				)}
				{(this.state.pageStatus == PAGE_STATUS_PLACING_TEST1A) && (
					<WaitScreen
						left={this.state.left}
						q_idx={this.state.q_idx}
						pageStatus={this.state.pageStatus}
						head={this.props.t("Test in process")}
						subhead={this.props.t("Waiting for action on mobile device...")}
					/>
				)}
				{this.state.pageStatus == PAGE_STATUS_PLACING_TEST1B && <Test1B />}
				{this.state.nextTest &&
					this.state.pageStatus >= PAGE_STATUS_PLACING_TEST2 && (
						<WaitScreen
							left={this.state.left}
							q_idx={this.state.q_idx}
							pageStatus={this.state.pageStatus}
							head={this.state.head}
							subhead={this.state.subhead}
						/>
					)}
				{!this.state.nextTest &&
					this.state.pageStatus == PAGE_STATUS_PLACING_TEST2 && (
						<Test2
							orientation={getOrientation(this.state.or)}
							size={getSize(this.state.va, parseFloat(this.state.scR))}
							cover={this.state.cover}
							left={this.state.left}
							wearing={this.state.wearing}
						/>
					)}
				{!this.state.nextTest &&
					this.state.pageStatus == PAGE_STATUS_PLACING_TEST3 && (
						<Test3
							orientation={Q3_ORIENTATIONS[this.state.or]}
							size={getSize(INITVA-this.state.scR, 67+parseFloat(this.state.scR))}
							cover={this.state.cover}
							left={this.state.left}
							wearing={this.state.wearing}
						/>
					)}
				{!this.state.nextTest &&
					this.state.pageStatus == PAGE_STATUS_PLACING_TEST4 && (
						<Test4
							orientation={getOrientation(
								this.state.or,
								Number(localStorage.getItem(Q_ARR[this.state.q_idx - 2]))
							)}
							size={getSize(this.state.va, parseFloat(this.state.scR))}
							cover={this.state.cover}
							left={this.state.left}
							wearing={this.state.wearing}
						/>
					)}
			</>
		); //%NOTE% check if orientation good ,,, this.state.left?this.state.orl:this.state.orr
	}
}
function getCartoon(pageStatus, q_idx) {
	let cartoon_idx;
	if (q_idx == 0) {
		cartoon_idx = pageStatus - 1;
	} else if (q_idx >= 8) {
		cartoon_idx = (q_idx % 2) + 5;
	} else {
		cartoon_idx = (q_idx % 2) + 3;
	}
	return cartoon_idx;
}

function WaitScreen (props) {
	const { i18n } = useTranslation();
	const cartoon_arr = [
		cartoonOne,
		cartoonTwo,
		cartoonBlank,
		cartoonLeft,
		cartoonRight,
		cartoonLeftGlass,
		cartoonRightGlass,
	]

	const getSoundDesk = () => {
		const { pageStatus, left } = props;
		switch (pageStatus) {
			case 1: return "mpage12";
			case 2: return "mpage13";
			case 3: return "mpage14test1a";
			case 5:
			case 6:
			case 7:
				const sound = left ? "mpage15right" : "mpage15left";
				return sound;
			default:
				return ""; 
		}
	 };

	const soundDesk = getSoundDesk();
	return (
		<>
			<div className="frame" style={{ position: "relative", zIndex: 1}}>
				<div className="connectionContent">
					<div>
						<img
							src={
								cartoon_arr[
									getCartoon(props.pageStatus, props.q_idx)
								]
							}
							alt=""
							style={{ width: "70%" }}
						/>
					</div>
					<div className="connectionContentTitle">
						<h1>{props.head}</h1>
					</div>
					<div className="connectionContentText">
						<h2>{props.subhead}</h2>
					</div>
					{(<div style={{marginTop: "35px" }}>
						<AudioGuide 
							lang={i18n.language}
							sound={soundDesk}
						/>
					</div>)}
					<Spinner
						as="span"
						animation="grow"
						size="m"
						role="status"
						aria-hidden="true"
					/>
				</div>
			</div>
		</>
	);
}

function Test1B(props) {
	const { i18n } = useTranslation();
	return (
		<div className="test-instructions-wrapper">
			<div className="frame">
				<img src={nearFar} alt="" style={{ width: "60%" }} />
				{(<div style={{marginTop: "35px" }}>
							<AudioGuide 
								lang={i18n.language}
								sound={"mpage14test1b"}
							/>
						</div>)}
			</div>
		</div>
	);
}

function TestHeader(props) {

	const { t } = useTranslation();
	return (
		<div className="test-instructions">
			{!props.wearing ? (
				<h2>
					{t("Glasses OFF")}
				</h2>
			) : (
				<h2>
					{t("Glasses ON")}
				</h2>
			)}
			{(props.left) ? (
				<h2>
					{t("Cover your RIGHT eye")}
				</h2>
			) : (
				<h2>
					{t("Cover your LEFT eye")}
				</h2>
			)}
		</div>
	);
}

class Test2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = { sketch: big_c };
	}
	render() {
		return (
			<div className="test-instructions-wrapper">
				<TestHeader left={this.props.left} wearing={this.props.wearing} />
				
				<div className="canvas-wrapper">
					<ReactP5Wrapper
						sketch={this.state.sketch}
						i={this.props.orientation}
						r={this.props.size}
						c={this.props.cover}
					/>
				</div>
			</div>
		);
	}
}

class Test3 extends React.Component {
	// %NOTE% Cover?
	constructor(props) {
		super(props);
		this.state = { sketch: axis };
	}
	render() {
		return (
			<div className="test-instructions-wrapper">
				<TestHeader left={this.props.left} wearing={this.props.wearing} />
				
				<div className="canvas-wrapper">
					<ReactP5Wrapper
						sketch={this.state.sketch}
						i={this.props.orientation}
						r={this.props.size}
					/>
				</div>
			</div>
		);
	}
}

class Test4 extends React.Component {
	constructor(props) {
		super(props);
		this.state = { sketch: big_c };
	}
	render() {
		return (
			<div className="test-instructions-wrapper">
				<TestHeader left={this.props.left} wearing={this.props.wearing} />
				
				<div className="canvas-wrapper">
					<ReactP5Wrapper
						sketch={this.state.sketch}
						i={this.props.orientation}
						r={this.props.size}
						c={this.props.cover}
					/>
				</div>
			</div>
		);
	}
}

const Modal = ({ isOpen, onClose, token }) => {
const { t, i18n } = useTranslation();
	if (!isOpen) return null;
 const linkQRCode = `${QR_DOMAIN}/mconnection?token=${token}&lang=${i18n.language}`;
	return (
		<div 
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 1000
			}}
			onClick={onClose}
		>
			<div 
				style={{
					backgroundColor: 'white',
					padding: '50px',
					borderRadius: '8px',
					minWidth: '300px',
					maxWidth: '90%',
					maxHeight: '90vh',
					overflow: 'hidden',
					position: 'relative'
				}}
				onClick={e => e.stopPropagation()}
			>
				<div style={{ 
					display: 'flex', 
					justifyContent: 'space-between', 
					alignItems: 'center',
					marginBottom: '15px',
					flexDirection: "column"
				}}>
					<h2 style={{
						marginBottom: "20px",
						color: "#216e75",
						fontSize: "clamp(2rem, 2vw, 4rem)",
						textAlign: "center",
						maxWidth: "600px"
					}}>{t("Mobile Device Disconnected")}</h2>
					<h3 style={{ marginBottom: "20px",fontSize: "20px",width: "400px",textAlign: "center"}}>{t("Scan QR or enter the link below on your mobile device to continue")}</h3>
					<div className="qr-code-wrapper">
							<QRCode
								bgColor="#FFF"
								fgColor="#000"
								level="Q"
								style={{ width: 350 }}
								value={linkQRCode}
							/>
					</div>
					<a style={{marginTop: "20px"}} href={linkQRCode}>
						{linkQRCode}
					</a>
				</div>
				
			</div>
		</div>
	);
 };

export default withTranslation()(withRouter(Page12StartSession));
