// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { withRouter } from 'react-router-dom';
import React from 'react';
import qs from 'query-string';
import { ReactP5Wrapper } from "react-p5-wrapper";
import { c_button } from "../sketches/c_button";
import { axis_button } from "../sketches/axis_button";
import { Q3_ORIENTATIONS } from '../utils/constants';
import { withTranslation, useTranslation } from "react-i18next";

import cartoonOne from "../assets/img/cartoon1.svg";
import cartoonTwo from "../assets/img/cartoon2.svg";
import cartoonBlank from "../assets/img/cartoonBLANK.jpeg";
import cartoonLeft from "../assets/img/cartoonCoverLeftEye.svg";
import cartoonRight from "../assets/img/cartoonCoverRightEye.svg";
import cartoonLeftGlass from "../assets/img/cartoonCoverLeftEyeGlasses.svg";
import cartoonRightGlass from "../assets/img/cartoonCoverRightEyeGlasses.svg";
import { useRealtime } from '../hooks/useRealtime';
import { getQuery } from '../utils';

class MPageTests extends React.Component {
  state = {
      pageStatus: 5,
      axis: 0,
      axis_idx: 0,
      init: true,
      first: true,
      nextPage: false,
      changePage: false,
      left: false,
      nextTest: false,
      openAnswer: false,
      q_idx: 0,
      loading: true,
      test: {}
  };

  componentWillMount() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const realtime = useRealtime("mobile");
    const query = getQuery();
    
    realtime.subscribe(query.token, () => {
      realtime.listener(query.token, 'm-open-dynamic-answers', (data) => {
        this.reloadState(data);
        this.setState({ test: data, loading: false });
  
        realtime.listener(query.token, 'm-update-message', (data) => {
          this.reloadState(data);
        });
      });
      
      realtime.trigger(query.token, 'open-dynamic-answers', { token: query.token });
    });
  }

  reloadState(data) {
    let { code, pageStatus, infos: { finalized } = {} } = data;

    if (pageStatus === 8 || code === "E010005" || finalized) {
      return this.redirectToEnd();
    } else {
      const { infos: { changePage, left, nextTest, q_idx, openAnswer } } = data;
      console.log('setStatus in getStatus mtests');
      this.setState({ ...this.state, test: data, pageStatus, changePage, left, nextTest, q_idx, openAnswer });
      console.log('before setState in getStatus mtests', this.state);

      this.pollingStatus(data);
    }
  }

  componentDidUpdate() {
      window.addEventListener('popstate', () => {
          this.props.history.go(1);
      });
  }

  componentWillUnmount() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const realtime = useRealtime("mobile");
    realtime.disconnect()
  }

  goNext(v) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const realtime = useRealtime("mobile");
    const query = getQuery();

    realtime.trigger(query.token, 'update-message', { action: 'sendAnswer', data: { answer: v, token: query.token }});
  }

  prepareToAnswer() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const realtime = useRealtime("mobile");
    const query = getQuery();

    const payload = { 
      token: query.token,
      infos: {
        pageStatus: this.state.pageStatus,
        changePage: false,
        left: this.state.left,
        nextTest: this.state.nextTest,
        openAnswer: true,
        first: false,
      }
    };
    realtime.trigger(query.token, 'update-message', { 
      action: 'prepareToAnswer', 
      data: payload
    });
  }

  redirectToEnd() {
    this.props.history.replace({ pathname: '/mend' });
    this.props.history.push({ pathname: '/mend' });
  }

  pollingStatus(data) {  
    console.log('resp getInfoStatus mtests', data);
    console.log('state mtests', this.state);
    let { axis = 0, pageStatus, infos } = data || {};
    if (pageStatus === 8 || (infos && infos.finalized)) {
      return this.redirectToEnd();
    }

    let { changePage, left, nextTest, q_idx, openAnswer, axis_idx = 0 } = infos;
    if(infos && changePage) {
      console.log('infos and changePage', this.state);
      if (this.state.pageStatus === 6) {
        axis = Q3_ORIENTATIONS[axis_idx];
      } else {
        axis_idx = 0;
      }
      this.setState({ ...this.state, axis, axis_idx: 0, init: true, pageStatus, changePage, left, nextTest, q_idx, openAnswer });
    } else if (this.state.pageStatus === 6) {
      axis = Q3_ORIENTATIONS[axis_idx];
      if (axis_idx === Q3_ORIENTATIONS.length) {
          axis_idx = 0
      }
      this.setState({ ...this.state, init: false, axis, axis_idx });
    }
  }

  render() {
      if (this.state.loading) {
        return null;
      }
      console.log('this.state', this.state);
      const size = 80;

      const cartoon_arr= [
        cartoonOne,
        cartoonTwo,
        cartoonBlank,
        cartoonLeft,
        cartoonRight,
        cartoonLeftGlass,
        cartoonRightGlass,
      ];

      const getCartoon = (pageStatus, q_idx) => {
        console.log(pageStatus, q_idx);
        let cartoon_idx;
        if (q_idx === 0) {
          cartoon_idx = pageStatus - 1;
        } else if (q_idx >= 8) {
          cartoon_idx = (q_idx % 2) + 5;
        } else {
          cartoon_idx = (q_idx % 2) + 3;
        }
        // console.log('image ->>>>', q_idx, cartoon_idx)
        return cartoon_idx;
      }
      // console.log('this.state -->>', this.state);
      return (
          <div className="mobile-tests">

              <div className="main-view">
                {(this.state.changePage || !this.state.openAnswer) && 
                  <FinalizedPage
                    state={this.state}
                    image={cartoon_arr[getCartoon(this.state.pageStatus, (this.state.q_idx) || 0)]}
                  />}
                  {
                      (this.state.changePage === false && this.state.openAnswer) && (
                          <div className="main-view-inset">
                            
                              <h2>{this.state.pageStatus === 6 ? this.props.t('Select which side is clearer') : this.props.t('Select what you see')}</h2>

                              <div className="main-view-inset-wrapper">
                                  {this.state.pageStatus !== 6 && <div className="centerv">
                                      {this.state.pageStatus < 7 && <span onClick={() => { this.goNext(2) }}>
                                          <ReactP5Wrapper sketch={c_button} i={90} r={size} />
                                      </span>}
                                      <div className="centerh">
                                          <span style={{ paddingRight: '110px' }} onClick={() => { this.goNext(1) }}>
                                              <ReactP5Wrapper sketch={c_button} i={getOrientation(1, this.state.axis, this.state.pageStatus)} r={size} />
                                          </span>
                                          <span onClick={() => { this.goNext(this.state.pageStatus < 7 ? 3 : 2) }}>
                                              <ReactP5Wrapper sketch={c_button} i={getOrientation(2, this.state.axis, this.state.pageStatus)} r={size} />
                                          </span>
                                      </div>
                                      {this.state.pageStatus < 7 && <span onClick={() => { this.goNext(4) }}>
                                          <ReactP5Wrapper sketch={c_button} i={270} r={size} />
                                      </span>}
                                  </div>}

                                  {this.state.pageStatus === 6 && <div className="centerv">
                                      <div className="centerh">
                                          <span style={{ paddingRight: '90px' }} onClick={() => { this.goNext(1) }}>
                                              <ReactP5Wrapper sketch={axis_button} i={getOrientation(1, this.state.axis, this.state.pageStatus)} r={60} />
                                          </span>
                                          <span onClick={() => { this.goNext(3) }}>
                                              <ReactP5Wrapper sketch={axis_button} i={getOrientation(2, this.state.axis, this.state.pageStatus)} r={60} />
                                          </span>
                                      </div>
                                  </div>}
                              </div>
                          </div>
                      )
                  }
              </div>

              <div className="main-nav">
                  {this.state.openAnswer && (
                      <div className="button-light" onClick={() => { this.goNext(5) }}>
                          {this.props.t("Can't Tell")}
                      </div>
                  )}
                  {(!this.state.openAnswer) && (
                    <div className="button-default" onClick={() => this.prepareToAnswer()}>
                        {this.props.t("Next Stage")}
                    </div>
                  )}
              </div>
          </div>
      )
  }
}

function FinalizedPage(props) {
  const { t } = useTranslation();
  const query = qs.parse(window.location.search);
  return (
    <>
      <div className="main-view-inset">
        <div className="connectionContent" style={{position: "inherit"}}>
          <div>
            <img
              src={props.image}
              alt=""
              style={{ width: "70%" }}
            />
          </div>
          <div className="connectionContentTitle">
            <h1>{t("Stage Complete!")}</h1>
          </div>
          <div className="connectionContentText">
            {(props.state.left === true) && 
              (<h2 style={{ fontSize: '1.2rem', color: '#0db2e3' }}>{t("Cover your RIGHT eye then press 'Next Stage'")}</h2>)}
            {(props.state.left === false || props.state.left === undefined) && 
              (<h2 style={{ fontSize: '1.2rem', color: '#0db2e3' }}>{t("Cover your LEFT eye then press 'Next Stage'")}</h2>)}
          </div>
        </div>
      </div>
    </>
  )
}

// or value -> actual orientation for p5
function getOrientation(or, axis, pageStatus) {
    const newAxis = parseInt(axis);
    let theta = 0
    let delta = (pageStatus === 6) ? 90 : 180
    // left side
    if (newAxis >= 0 && newAxis < 90) {
        theta = newAxis + (or - 1) * delta
        // right side
    } else if (newAxis >= 90 && newAxis < 180) {
        theta = newAxis + (or) * delta
    } else {
        console.log('invalid axis: ' + newAxis)
    }
    return theta;
}

export default withTranslation()(withRouter(MPageTests));
