// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './styles.css';
import TestWrapper from "../components/TestWrapper";
import { useTranslation } from 'react-i18next';

// import { ReactP5Wrapper } from "react-p5-wrapper";
// import KeyboardEventHandler from 'react-keyboard-event-handler';
// import * as test from "../../../sketches/t2_monocular";

const qsName = 'DOB';
//const questionText = "What's your age?";
const PATH = '/gender';



export default function Page2Age() {

    const { t, i18n } = useTranslation();

    let history = useHistory();
    const [BYear, setBYear] = useState('');
    const [BMonth, setBMonth] = useState('');
    const [BDay, setBDay] = useState('');

    const buttonAction = (qsName, BYear, BMonth, BDay, path) => {
        let today = new Date().getFullYear()
        let age = today - BYear
        if (BMonth.length == 1) {
            BMonth = "0" + BMonth
        }
        if (BDay.length == 1) {
            BDay = "0" + BDay
        }

        if (BYear.length == 4 && Number(BYear) > 0 && BMonth.length == 2 && Number(BMonth) > 0 && BDay.length == 2 && Number(BDay) > 0) {


            let dob = BYear + "-" + BMonth + "-" + BDay

            localStorage.setItem("dob", dob)
            localStorage.setItem("age", age)

            history.push({
                pathname: path,
            });
        }
        else { alert(t("there must be four (4) digits for year, two (2) for month, and two (2) for day")); }
    };

    return (

        <TestWrapper image={`images/test/002.jpg`}>

            <h2>{t("Type your birth year, month and day")}</h2>

            <div className="form-view">
                <Form>
                    <Form.Group>
                        <Form.Control type="number" placeholder={t("Birth year (yyyy)")} size={'lg'} min={1900} max={2022}
                            onChange={(v) => { 
                                if (v.target.value.length < 5) {
                                    setBYear(v.target.value) 
                                }
                            }}
                            value={BYear}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                    </Form.Group>
                </Form>

                <Form>
                    <Form.Group>
                        <Form.Control type="number" placeholder={t("Birth Month (mm)")} size={'lg'} min={1}
                            onChange={(v) => { 
                                if (v.target.value.length < 3) {
                                    setBMonth(v.target.value)
                                }
                            }}
                            value={BMonth}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                    </Form.Group>
                </Form>

                <Form>
                    <Form.Group>
                        <Form.Control
                            type="number" placeholder={t("Birth Day (dd)")} size={'lg'} min={0} max={31}
                            maxLength={2}
                            onChange={(v) => { 
                                if (v.target.value.length < 3) {
                                    setBDay(v.target.value)
                                } 
                            }}
                            value={BDay}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                    </Form.Group>
                </Form>
            </div>

            <div className="nav-view">
                <div
                    onClick={() => buttonAction(qsName, BYear, BMonth, BDay, PATH)}
                    className="nav-button"
                >
                    <div>{t("Submit")}</div>
                </div>
            </div>


        </TestWrapper>

    );



}
