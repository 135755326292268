import { useTranslation } from "react-i18next";
import { QRCode } from "react-qr-svg";

export default function DesktopQRCodeModal({ isOpen, onClose, link }) {
  const { t } = useTranslation();
  if (!isOpen) return null;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "50px",
          borderRadius: "8px",
          minWidth: "300px",
          maxWidth: "90%",
          maxHeight: "90vh",
          overflow: "hidden",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              marginBottom: "20px",
              color: "#216e75",
              fontSize: "clamp(2rem, 2vw, 4rem)",
              textAlign: "center",
              maxWidth: "600px",
            }}
          >
            {t("Mobile Device Disconnected")}
          </h2>
          <h3
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              width: "400px",
              textAlign: "center",
            }}
          >
            {t(
              "Scan QR or enter the link below on your mobile device to continue"
            )}
          </h3>
          <div className="qr-code-wrapper">
            <QRCode
              bgColor="#FFF"
              fgColor="#000"
              level="Q"
              style={{ width: 350 }}
              value={link}
            />
          </div>
          <a style={{ marginTop: "20px" }} href={link}>
            {link}
          </a>
        </div>
      </div>
    </div>
  );
}
