import { withRouter } from "react-router-dom";
import React, {useState, useEffect, useCallback} from "react";
import qs from "query-string";
import { useTranslation } from 'react-i18next';
import { useRealtime } from "../hooks/useRealtime";
import { getQuery } from "../utils";

function Mpage12mconnectionAndStepback(props) {
	const [state, setState] = useState({ pageStatus: undefined });
	const { t, i18n } = useTranslation();
	const realtime = useRealtime("mobile");
	const query = getQuery();
	const processCurrentPage = useCallback(({pageStatus}) => {
		const searchString = qs.stringify(query);
		if (pageStatus <= 1) {
			setState({ pageStatus: 1 });
		} else if (pageStatus === 2) {
			props.history.replace({
				pathname: "/placeobject",
				search: searchString,
			});
			props.history.push({
				pathname: "/placeobject",
				search: searchString,
			});
		} else if (pageStatus === 3) {
			props.history.replace({
				pathname: "/mtest1A",
				search: searchString,
			});
			props.history.push({
				pathname: "/mtest1A",
				search: searchString,
			});
		} else if (pageStatus === 4) {
			props.history.replace({
				pathname: "/mtest1B",
				search: searchString,
			});
			props.history.push({
				pathname: "/mtest1B",
				search: searchString,
			});
		} else { // > 5
			props.history.replace({
				pathname: "/mtests",
				search: searchString,
			});
			props.history.push({
				pathname: "/mtests",
				search: searchString,
			});
		}
	}, []);
	
	useEffect(() => {
		realtime.subscribe(query.token, () => {
			realtime.listener(query.token, 'm-connection-opened', processCurrentPage);
			realtime.trigger(query.token, 'update-message', { action: 'openQRCode', data: { pageStatus: 1, token: query.token }});
		});
		i18n.changeLanguage(query.lang)
		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	const Button1 = () => {
		const searchString = qs.stringify(query);
		props.history.replace({
			pathname: "/placeobject",
			search: searchString,
		});
		props.history.push({
			pathname: "/placeobject",
			search: searchString,
		});
	}; 

	if (state.pageStatus !== 1) {
		// waiting to redirect to correct route/state
		return null;
	}

	return (
		<>
			<div style={{maxHeight: "90vh", overflow: "hidden"}}>
				<div className="mobile-view">
					<h1>
						{t("Now follow the instructions on computer and move 10 feet away from your computer screen.")}
					</h1>
					<div className="nav-view">
						<div className="nav-button" onClick={Button1}>
							{t("Confirm")}
						</div>
					</div>
					<h2>{t("Accuracy is important!")}</h2>
				</div>
			</div>
		</>
	);
}

export default withRouter(Mpage12mconnectionAndStepback);
