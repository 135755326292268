import React, { useEffect } from "react";
import { useState } from "react";
import { QR_DOMAIN } from "../../utils/constants";
import { useRealtime } from "../../hooks/useRealtime";
import { getQuery } from "../../utils";
import { useNewTest } from "../../hooks/useNewTest";
import { useTranslation } from "react-i18next";
import DesktopQRCodeModal from "./desktop/DesktopQRCodeModal";

export const TestDesktop = () => {
  const [channelLoaded, setChannelLoaded] = useState(false);
  // TODO: ADD QRCODE MODAL
  const [link, setLink] = useState(null);
  const { t, i18n } = useTranslation();
  const [test, setTest] = useState(null);
  const [state, setState] = useState({});
  const [workflow, setWorkflow] = useState(null);
  const query = getQuery();
  const { get, moveToNextQuestion, loadWorkflow, finishTest } = useNewTest();
  const realtime = useRealtime("desktop");
  const [modalQRCode, setModalQRCode] = useState(false);

  // used when finish the test
  const textPrescription = {
    text_title: t("text_title"),
    text_subhead: t("text_subhead"),
    text_glasses: t("Glasses"),
    text_right_eye: t("Right Eye"),
    text_left_eye: t("Left Eye"),
    text_contacts: t("Contacts"),
    text_demo: t("text_demo"),
    text_sphere: t("FINAL RX SPHERE"),
    text_cylinder: t("Cylinder"),
    text_axis: t("AXIS"),
    text_power: t("Add Power"),
    text_subject: t("text_subject"),
    text_feedback_button: t("text_feedback_button"),
    text_feedback_text: t("text_feedback_text"),
  };

  const mountFeedbackMessage = (type, test) => {
    // cleanUp the test to send message to mobile
    // avoid allowed maximum (10240 bytes)

    const { steps, ...data } = test; // split steps
    const step = steps[test.workflow].state;

    return {
      type,
      state: step,
      test: data,
    };
  };

  const processWorkflowFeedback = (moveToNext) => {
    if (moveToNext) {
      moveToNextQuestion(test);
      setWorkflow(loadWorkflow(test));
    }

    setTest(test);
    setState(test.steps[test.workflow].state);

    let type = "ok";
    if (moveToNext) {
      type = "refresh";
    }

    realtime.trigger(test.token, "feedback", mountFeedbackMessage(type, test));
  };

  const processSkip = async (skip) => {
    // if is true, it's to refresh, else send the message has ok
    const moveToNext = await workflow.skip(test, skip);

    processWorkflowFeedback(moveToNext);
  };

  const processAnswer = async (answer) => {
    // if is true, it's to refresh, else send the message has ok
    const moveToNext = await workflow.answer(test, answer);

    processWorkflowFeedback(moveToNext);
  };

  const checkMobilePresence = () => {
    if (
      workflow.requireMobilePresence &&
      !realtime.hasMobilePresence(test.token)
    ) {
      setModalQRCode(true);
      return;
    }

    setModalQRCode(false);
  };

  useEffect(() => {
    const getTest = async (query_token) => {
      const test = await get(query_token);
      setLink(
        `${QR_DOMAIN}/test/mconnection?token=${test.token}&lang=${i18n.language}`
      );

      setWorkflow(loadWorkflow(test));
      setTest(test);
      setState(test.steps[test.workflow].state);

      realtime.subscribe(test.token, () => {
        realtime.listener(test.token, "connection", () => {
          realtime.trigger(
            test.token,
            "feedback",
            mountFeedbackMessage("refresh", test)
          );
        });

        // trigger feedback for case of refresh desktop notify the front
        realtime.trigger(
          test.token,
          "feedback",
          mountFeedbackMessage("refresh", test)
        );

        setChannelLoaded(true);
      });
    };
    getTest(query.token);
  }, [get, i18n.language, query.token, realtime]);

  useEffect(() => {
    // if channel is loaded and workflow is updated,
    // need to rebuild the answer and mobile presence detection listeners
    if (channelLoaded) {
      realtime.listener(
        test.token,
        "answer",
        async ({ answer }) => await processAnswer(answer)
      );

      realtime.listener(
        test.token,
        "skip",
        async ({ skip }) => await processSkip(skip)
      );

      realtime.addedDevice(test.token, checkMobilePresence);
      realtime.removedDevice(test.token, checkMobilePresence);

      checkMobilePresence();
    }
  }, [workflow, channelLoaded]);

  useEffect(() => {
    if (workflow && test) {
      if (workflow.isFinalStep) {
        finishTest(test, textPrescription);
      }
    }
  }, [test, workflow]);

  const answer = async (data) => {
    await processAnswer(data);
  };

  const skip = async (data) => {
    await processSkip(data);
  };

  return (
    <>
      <DesktopQRCodeModal
        isOpen={modalQRCode}
        onClose={() => {}} // do nothing here, it's not to close the Model clicking out
        link={link}
      />
      <div className="article-card">
        {state && test && workflow && (
          <workflow.DesktopComponent
            test={test}
            answer={answer}
            skip={skip}
            state={state}
          />
        )}
      </div>
    </>
  );
};
