import "../styles.css";
import { useTranslation } from "react-i18next";
import DesktopRectangle from "./DesktopRectangle";

export default function DesktopRectangleRight({ test, state }) {
  const { t } = useTranslation();

  return (
    <DesktopRectangle
      head={t("Glasses OFF")}
      subhead={t("Cover your LEFT eye")}
      test={test}
      state={state}
    />
  );
}
