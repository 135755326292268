import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles.css";
import { useTranslation } from "react-i18next";
import { axis_button } from "../../../sketches/axis_button";
import { Q3_ORIENTATIONS } from "../../../utils/constants";

export default function MobileRectangle({ answer, state }) {
  const { t } = useTranslation();
  const { or } = state;
  const size = 60;
  const orientation = (va) => Q3_ORIENTATIONS[or] + (va - 1) * 90;

  return (
    <div className="mobile-tests">
      <div className="main-view">
        <div className="main-view-inset">
          <h2>{t("Select which side is clearer")}</h2>
          <div className="main-view-inset-wrapper">
            <div className="centerv">
              <div className="centerh">
                <span
                  style={{ paddingRight: "90px" }}
                  onClick={() => {
                    answer(1);
                  }}
                >
                  <ReactP5Wrapper
                    sketch={axis_button}
                    i={orientation(1)}
                    r={size}
                  />
                </span>
                <span
                  onClick={() => {
                    answer(3);
                  }}
                >
                  <ReactP5Wrapper
                    sketch={axis_button}
                    i={orientation(2)}
                    r={size}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-nav">
        <div className="button-default" onClick={() => answer(5)}>
          {t("Can't Tell")}
        </div>
      </div>
    </div>
  );
}
