import "../styles.css";
import { useTranslation } from "react-i18next";
import nearFar from "../../../assets/img/nearfar.jpg";
import AudioGuide from "../../AudioGuide";

export default function DesktopWhichDevice() {
  const { i18n } = useTranslation();

  return (
    <div className="test-instructions-wrapper">
      <div className="frame">
        <img src={nearFar} alt="" style={{ width: "60%" }} />
        {
          <div style={{ marginTop: "35px" }}>
            <AudioGuide lang={i18n.language} sound={"mpage14test1b"} />
          </div>
        }
      </div>
    </div>
  );
}
