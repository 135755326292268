import { HashLink } from "react-router-hash-link";
import Image from "../assets/images/home-test.jpg";
import BasicInformation from "../assets/images/basic-information.jpg";
import OnlineExam from "../assets/images/online-exam.jpg";
import DigitalPrescription from "../assets/images/digital-prescription.jpg";
import NewGlasses from "../assets/images/new-glasses.jpg";
import Check from "../assets/images/check-2.svg";
import { LandingCard } from "../components/LandingPage/LandingCard";

const LandingPage = () => {
   const cards = [
      {
         title: "Questionário Inicial",
         subtitle: "Breve avaliação do seu histórico visual",
         image: BasicInformation,
      },
      {
         title: "Teste de Visão",
         subtitle: "Avaliação precisa usando seu computador e celular",
         image: OnlineExam,
      },
      {
         title: "Revisão Profissional",
         subtitle: "Análise dos resultados por especialistas",
         image: DigitalPrescription,
      },
      {
         title: "Prescrição Digital",
         subtitle: "Receba sua nova prescrição em até 24h",
         image: NewGlasses,
      },
   ];

   const benefitsList = [
      "Economia de Tempo - Teste completo em apenas 15 minutos, sem necessidade de agendamento",
      "Conforto Total - Faça o teste no conforto da sua casa, sem deslocamentos",
      "Tecnologia Avançada - Precisão garantida com nossa tecnologia patenteada",
      "Economia Real - Mais acessível que consultas tradicionais",
      "Flexibilidade - Faça o exame no horário que preferir",
   ];

   return (
      <section>
         <section className="landing-page">
            <article className="main-article">
               <div>
                  <h1>Seus Óculos Novos Sem Sair de Casa</h1>
                  <h4>
                     Faça seu exame de vista online em apenas 15 minutos e
                     receba sua prescrição digital
                  </h4>
               </div>

               <img src={Image} alt="Eye Test" />

               <HashLink className="button-btn" to="/signup">Iniciar meu Teste Agora</HashLink>
            </article>

            <article className="info-article">
               <h2>Como Funciona o Teste Online?</h2>
               <div className="card-wrapper">
                  {cards.map((card, i) => {
                     return (
                        <LandingCard
                           key={i}
                           title={card.title}
                           subtitle={card.subtitle}
                           image={card.image}
                        />
                     );
                  })}
               </div>
            </article>

            <article className="benefits-article">
               <h2>Benefícios do Exame Online</h2>
               <ul className="list-wrapper">
                  {benefitsList.map((item) => (
                     <li>
                        <img
                           src={Check}
                           style={{ height: "25px", marginRight: "10px" }}
                           alt="Check icon"
                        />
                        {item}
                     </li>
                  ))}
               </ul>

               <h2>Pronto para Atualizar sua Prescrição?</h2>
               <HashLink className="button-btn" to="/signup">Iniciar Meu Teste</HashLink>
            </article>
         </section>
      </section>
   );
};

export default LandingPage;