import { useTranslation } from "react-i18next";
import "./styles.css";
import { Spinner } from "react-bootstrap";
import AudioGuide from "../AudioGuide";

export default function TestWaitScreen({ image, head, subhead, sound }) {
  const { i18n } = useTranslation();
  return (
    <>
      <div className="frame" style={{ position: "relative", zIndex: 1 }}>
        <div className="connectionContent">
          {image && (
            <div>
              <img src={image} alt="" style={{ width: "70%" }} />
            </div>
          )}
          {head && (
            <div className="connectionContentTitle">
              <h1>{head}</h1>
            </div>
          )}
          {subhead && (
            <div className="connectionContentText">
              <h2>{subhead}</h2>
            </div>
          )}
          {sound && <AudioGuide lang={i18n.language} sound={sound} />}
          <Spinner
            as="span"
            animation="grow"
            size="m"
            role="status"
            aria-hidden="true"
          />
        </div>
      </div>
    </>
  );
}
