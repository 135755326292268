import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles.css";
import { useTranslation } from "react-i18next";
import { c_button } from "../../../sketches/c_button";

export default function Mobile2Circles({ answer, state }) {
  const { t } = useTranslation();
  const { axis } = state;
  const size = 80;

  const orientation = (or) => {
    const newAxis = parseInt(axis);
    let theta = 0;
    let delta = 180;
    // left side
    if (newAxis >= 0 && newAxis < 90) {
      theta = newAxis + (or - 1) * delta;
      // right side
    } else if (newAxis >= 90 && newAxis < 180) {
      theta = newAxis + or * delta;
    } else {
      console.log("invalid axis: " + newAxis);
    }
    return theta;
  };

  return (
    <div className="mobile-tests">
      <div className="main-view">
        <div className="main-view-inset">
          <h2>{t("Select what you see")}</h2>
          <div className="main-view-inset-wrapper">
            <div className="centerv">
              <div className="centerh">
                <span
                  style={{ paddingRight: "110px" }}
                  onClick={() => {
                    answer(1);
                  }}
                >
                  <ReactP5Wrapper
                    sketch={c_button}
                    i={orientation(1)}
                    r={size}
                  />
                </span>
                <span
                  onClick={() => {
                    answer(2);
                  }}
                >
                  <ReactP5Wrapper
                    sketch={c_button}
                    i={orientation(2)}
                    r={size}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-nav">
        <div className="button-default" onClick={() => answer(5)}>
          {t("Can't Tell")}
        </div>
      </div>
    </div>
  );
}
