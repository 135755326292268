import "../styles.css";
import { useTranslation } from "react-i18next";

export default function MobileCountFeet({ answer }) {
  const { t } = useTranslation();

  // TODO: desktop using countFeet, mobile not ???
  // const countFeet = test?.steps?.gender?.result?.steps;

  return (
    <>
      <div style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <div className="mobile-view">
          <h1>
            {t(
              "Now follow the instructions on computer and move 10 feet away from your computer screen."
            )}
          </h1>
          <div className="nav-view">
            <div className="nav-button" onClick={() => answer({})}>
              {t("Confirm")}
            </div>
          </div>
          <h2>{t("Accuracy is important!")}</h2>
        </div>
      </div>
    </>
  );
}
