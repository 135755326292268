import { withRouter } from "react-router-dom";
import React, { useEffect } from "react";
import qs from "query-string";
import "./styles.css";
import { useTranslation } from 'react-i18next';
import { getQuery } from "../utils";
import { useRealtime } from "../hooks/useRealtime";

function MPageTest1A(props) {
    const { t } = useTranslation();
	const realtime = useRealtime("mobile");
	const query = getQuery();

	useEffect(() => {
		realtime.trigger(query.token, 'update-message', { action: 'updateStatus', data: { pageStatus: 3, token: query.token }});
		window.addEventListener("popstate", () => {
			props.history.go(1);
		});
	}, []);

	const Button1 = (val) => {
		if (val == 1) {
			localStorage.setItem("Answer1a", "near");
		} else if (val == 2) {
			localStorage.setItem("Answer1a", "far");
		} else if (val == 4) {
			localStorage.setItem("Answer1a", "not sure");
		}

		realtime.trigger(query.token, 'update-message', { action: 'sendAnswer', data: { answer: val, token: query.token }});

		const searchString = qs.stringify(query);
		props.history.replace({
			pathname: "/mtest1B",
			search: searchString,
		});
		props.history.push({
			pathname: "/mtest1B",
			search: searchString,
		});
	};
	return (
		<>
			<div style={{maxHeight: "90vh", overflow: "hidden"}}>
				<div className="mobile-view">
					<h1>{t("Question 1")}</h1>
					<h2>{t("What is easier for you to see when you don't have your glasses?")}</h2>
					<div className="nav-buttons">
						<div className="nav-button" onClick={() => Button1(1)}>
							{t("Near Objects")}
						</div>
						<div className="nav-button" onClick={() => Button1(2)}>
							{t("Far Objects")}
						</div>
						<div className="nav-button" onClick={() => Button1(4)}>
							{t("Not Sure")}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default withRouter(MPageTest1A);
