import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles.css";
import { big_c } from "../../../sketches/big_c";
import { getSize2 } from "../../../utils/core";

export default function Desktop2Circles({ head, subhead, state }) {
  const { or, ratio, axis, currentVaIdx } = state;

  // TODO: simplify this orientation logic
  const orientation = () => {
    let theta = 0;

    if (axis >= 0 && axis < 90) {
      theta = axis + (or - 1) * 180;
      // right side of screen test 4
    } else if (axis >= 90 && axis < 180) {
      theta = axis + or * 180;
    } else {
      console.log("invalid axis: " + axis);
    }
    return theta;
  };

  const size = getSize2(currentVaIdx, ratio);

  // TODO: improvement with cover(true, false) to return feedback to user?
  // cover = true draw full component "like" disabled status
  const cover = false;

  return (
    <div className="test-instructions-wrapper">
      <div className="test-instructions">
        <h2>{head}</h2>
        <h2>{subhead}</h2>
      </div>

      <div className="canvas-wrapper">
        <ReactP5Wrapper sketch={big_c} i={orientation()} r={size} c={cover} />
      </div>
    </div>
  );
}
