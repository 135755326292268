import { useTranslation } from "react-i18next";
import "../styles.css";

export default function MobileCoverEye({ image, head, subhead, answer }) {
  const { t } = useTranslation();

  return (
    <div className="mobile-tests">
      <div className="main-view">
        <div className="main-view-inset">
          <div className="connectionContent" style={{ position: "inherit" }}>
            {image && (
              <div>
                <img src={image} alt="" style={{ width: "70%" }} />
              </div>
            )}
            {head && (
              <div className="connectionContentTitle">
                <h1>{head}</h1>
              </div>
            )}
            {subhead && (
              <div className="connectionContentText">
                <h2 style={{ fontSize: "1.2rem", color: "#0db2e3" }}>
                  {subhead}
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="main-nav">
        <div className="button-default" onClick={() => answer({})}>
          {t("Next Stage")}
        </div>
      </div>
    </div>
  );
}
