import "../styles.css";
import { useTranslation } from "react-i18next";

export default function MobileNearOrFarObjects({ answer }) {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <div className="mobile-view">
          <h1>{t("Question 1")}</h1>
          <h2>
            {t(
              "What is easier for you to see when you don't have your glasses?"
            )}
          </h2>
          <div className="nav-buttons">
            <div className="nav-button" onClick={() => answer("near")}>
              {t("Near Objects")}
            </div>
            <div className="nav-button" onClick={() => answer("far")}>
              {t("Far Objects")}
            </div>
            <div className="nav-button" onClick={() => answer("not sure")}>
              {t("Not Sure")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
