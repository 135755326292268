import axios from "axios";
import Pusher from "pusher-js";
import { URL_ROUTE_USER } from "../utils/constants";

// Pusher.logToConsole = true;

class Realtime {
  instance = null;
  pusher = null;

  static getInstance(typeUser) {
    if (!this.instance) {
      this.instance = new Realtime();
      this.instance.connect(typeUser);
    }
    return this.instance;
  }

  connect(typeUser) {
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      channelAuthorization: {
        customHandler: async (channel, callback) => {
          axios
            .post(
              URL_ROUTE_USER + "/pusher-auth",
              { ...channel, typeUser },
              { headers: { "Content-type": "application/json; charset=UTF-8" } }
            )
            .then((res) => {
              callback(false, res.data);
            })
            .catch((error) => {
              callback(
                true,
                error.response ? error.response.data : error.message
              );
            });
        },
      },
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
  }

  unbind(channelName) {
    console.log("unbind events to " + channelName);
    let channel = this.pusher.channel(`presence-${channelName}`);
    if (channel) {
      channel.unbind();
    }
  }

  disconnect() {
    if (this.pusher) {
      console.log("disconnecting pusher");
      this.pusher.disconnect();
    }
  }

  subscribe(channelName, callback) {
    let channel = this.pusher.channel(`presence-${channelName}`);
    if (channel) {
      return callback(channel);
    }
    channel = this.pusher.subscribe(`presence-${channelName}`);
    channel.bind("pusher:subscription_succeeded", () => {
      channel.unbind("pusher:subscription_succeeded");
      return callback(channel);
    });
  }

  addedDevice(channelName, callback) {
    let channel = this.pusher.channel(`presence-${channelName}`);
    channel.bind("pusher:member_added", (member) => callback(member));
  }

  removedDevice(channelName, callback) {
    let channel = this.pusher.channel(`presence-${channelName}`);
    channel.bind("pusher:member_removed", (member) => callback(member));
  }

  hasMobilePresence(channelName) {
    let found = false;
    let channel = this.pusher.channel(`presence-${channelName}`);
    channel.members.each((member) => {
      if (member.info.type === "mobile") {
        found = true;
      }
    });
    return found;
  }

  listener(channelName, event, callback) {
    this.subscribe(channelName, (channel) => {
      channel.unbind(`client-${event}`);
      channel.bind(`client-${event}`, callback);
    });
  }

  trigger(channelName, event, data, userId = "") {
    this.subscribe(channelName, (channel) => {
      channel.trigger(`client-${event}`, data);
    });
  }
}

export const useRealtime = (typeUser = "default") => {
  return Realtime.getInstance(typeUser);
};
