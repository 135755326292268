import Footer from "../../../Footer";
import Header from "../../Header";
import "../styles.css";
import { useTranslation } from "react-i18next";

export default function DesktopThankyou() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="thank-you-page">
        <div className="subscription-view-notice">
          <h2>{t("Test completed successfully.")}</h2>
          <p>
            {t(
              "Please hold on for a moment, our specialists are reviewing your prescription."
            )}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
