import dayjs from "dayjs";
import { Fragment, useState } from "react";
import {
  Accordion,
  ButtonGroup,
  Card,
  ListGroup,
  Table,
  ToggleButton,
  useAccordionToggle,
} from "react-bootstrap";
import { useNewTest } from "../hooks/useNewTest";

export default function TestLogTable({ test }) {
  if (!test) {
    // TODO: waiting test loading, add a spinner here?
    return null;
  }

  const isOldTest = test.records && test.records.length;

  return isOldTest ? <OldTestLogTable test={test} /> : <LogTable test={test} />;
}

const LogTable = ({ test }) => {
  const { getTestLogs } = useNewTest();

  const data = getTestLogs(test);

  return (
    <>
      <Card style={{ width: "100%", marginRight: "5px" }}>
        <Card.Header>Table Log</Card.Header>
        <ListGroup variant="flush">
          {data.map((item, i) => (
            <ResultItem item={item} key={i}></ResultItem>
          ))}
        </ListGroup>
      </Card>
    </>
  );
};

const ResultItem = ({ item }) => {
  return (
    <ListGroup.Item>
      <Accordion>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "20% 40% 30% 10%",
          }}
        >
          <span>
            <small>{dayjs(item.finalizedAt).format("MM/DD/YY HH:mm:ss")}</small>
          </span>
          <span>{item.test}</span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {item.results.map((result, j) => (
              <span key={j}>{result}</span>
            ))}
          </div>
          <span>
            {item.answers?.length ? <CustomToggle eventKey="0" /> : <></>}
          </span>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Accordion.Collapse eventKey="0">
            <AnswerTable answers={item.answers} />
          </Accordion.Collapse>
        </div>
      </Accordion>
    </ListGroup.Item>
  );
};

const AnswerTable = ({ answers }) => {
  if (!answers?.length) {
    return null;
  }

  const heads = Object.keys(answers[0]);

  const rows = answers.map((a) => Object.values(a));

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {heads.map((head, i) => (
            <th key={i}>{head}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((answer, i) => (
          <tr key={i}>
            {answer.map((a, j) => (
              <td key={j}>{a}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const CustomToggle = ({ eventKey }) => {
  const [checked, setChecked] = useState(false);
  const toggleAction = useAccordionToggle(eventKey, () => {
    setChecked(!checked);
  });

  return (
    <ButtonGroup toggle className="mb-2">
      <ToggleButton
        variant="outline-secondary"
        type="checkbox"
        checked={checked}
        onChange={toggleAction}
      >
        logs
      </ToggleButton>
    </ButtonGroup>
  );
};

const OldTestLogTable = ({ test }) => {
  const ItemLogTable = (props) => {
    const removePage = [5, 6, 7].includes(props.page);
    const resultColum = false; //!!props.results // deixar com a validacao do results.
    const pages = [];
    pages[3] = "Answer 1";
    pages[4] = "Answer 2";
    pages[5] = "Test1";
    pages[6] = "Test2";
    pages[7] = "Test3";
    const titlePage = `${pages[props.page]} - ${props.left ? "Left" : "Right"}`;
    return (
      <tr class="table-row">
        {!resultColum && (
          <td class="col1">
            {" "}
            <strong>{removePage ? titlePage : pages[props.page]}</strong>{" "}
          </td>
        )}
        {/* { resultColum && (<td class="col1"></td>)} */}

        {!resultColum && props.page !== 6 && (
          <td class="col2">
            {" "}
            {removePage &&
              (props.answerDesktop?.answerCorrect
                ? "Correct"
                : "Incorrect")}{" "}
          </td>
        )}
        {/* { resultColum && (<td class="col1"></td>)} */}
        {!resultColum && props.page !== 6 && (
          <td class="col3">{props.answerDesktop?.va}</td>
        )}
        {/* { resultColum && (<td class="col1"></td>)} */}
        {!resultColum && props.page === 6 && (
          <td class="col3" style={{ widt: "100%" }}>
            axisX:{props.answerDesktop?.axis_x} - axisY:
            {props.answerDesktop?.axis_y}
          </td>
        )}
        {/* remover a negacao para voltar ao normal  */}
        {!resultColum && (
          <td class="col4">
            {props.results?.value?.name} - {props?.results?.value?.value}
          </td>
        )}
        {/* { !resultColum && (<td class="col1"></td>)} */}
      </tr>
    );
  };

  return (
    <>
      <div class="tableLog" style={{ width: "90%" }}>
        <h2>Table log</h2>
        <table class="responsive-table">
          <tr class="table-header">
            <td class="col1">Test - Eye</td>
            <td class="col2">Answer?</td>
            <td class="col3">Generate Value</td>
            <td class="col4">Results</td>
          </tr>
          {test?.records?.map((item, i) => (
            <ItemLogTable {...item} />
          ))}
        </table>
      </div>
    </>
  );
};
