import "../styles.css";
import { useTranslation } from "react-i18next";
import Desktop4Circles from "./Desktop4Circles";

export default function Desktop4CirclesRightGlassON({ test, state }) {
  const { t } = useTranslation();

  return (
    <Desktop4Circles
      head={t("Glasses ON")}
      subhead={t("Cover your LEFT eye")}
      test={test}
      state={state}
    />
  );
}
