import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import cartoonCoverLeftEye from "../../../assets/img/cartoonCoverLeftEye.svg";

export default function DesktopCoverLeft() {
  const { t } = useTranslation();

  return (
    <TestWaitScreen
      sound="mpage15left"
      image={cartoonCoverLeftEye}
      head={t("Stage Complete!")}
      subhead={t("Cover your LEFT eye then press 'Next Stage'")}
    />
  );
}
