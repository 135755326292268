import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import {
  FunctionAddPower,
  FunctionEyeValence,
  SPEMethods,
  FunctionMeasuredRx,
  FunctionWeightedRx,
} from "../intake/AJmath";
import TestLogTable from "./TestLogTable";

export default function VerifyView({ data }) {
  const [keysData, setKeysData] = useState([]);
  const [dataParam, setDataParam] = useState([]);
  const [formVar, setFormVar] = useState({
    user_id: "",
    age: "",
    dob: "",
    name: "",
    gender: "",
    KnowSpec: "",
    HaveSpec: "",
    KnowContact: "",
    CL_OldBrandOD: "",
    CL_OldBrandOS: "",
    ratio: "",
    OldSphOD: "",
    OldSphOS: "",
    OldCylOD: "",
    OldCylOS: "",
    OldAxisOD: "",
    OldAxisOS: "",
    CL_OldSphOD: "",
    CL_OldCylOD: "",
    CL_OldAxisOD: "",
    CL_OldBaseCurveOD: "",
    CL_OldDiamOD: "",
    CL_OldSphOS: "",
    CL_OldCylOS: "",
    CL_OldAxisOS: "",
    CL_OldBaseCurveOS: "",
    CL_OldDiamOS: "",
    NumberOfSteps: "",
    Answer1a: "",
    Answer1b: "",
    VAccOD: "",
    VAccOS: "",
    VAscOD: "",
    VAscOS: "",
    PrimeDrPOVOD: "",
    PrimeDrPOVOS: "",
    VAaOD: "",
    VAaOS: "",
    Add: "",
    EyeValenceOD: "",
    EyeValenceOS: "",
    EyeValenceSafety: "",
    SPEscOD: "",
    SPEscOS: "",
    SPEaOD: "",
    SPEaOS: "",
    MeasuredSphOD: "",
    MeasuredSphOS: "",
    MeasuredCylOD: "",
    MeasuredCylOS: "",
    MeasuredAxisOD: "",
    MeasuredAxisOS: "",
    FinalSphOD: "",
    FinalCylOD: "",
    FinalAxisOD: "",
    FinalCLAxisOD: "",
    FinalSphOS: "",
    FinalCylOS: "",
    FinalAxisOS: "",
    FinalCLAxisOS: "",
    uuid: "",
    FinalCLSphOD: "",
    FinalCLCylOD: "",
    FinalCLSphOS: "",
    FinalCLCylOS: "",
    updateDate: "",
    newFinalSphOD: "",
    newFinalCylOD: "",
    newFinalAxisOD: "",
    newFinalCLSphOD: "",
    newFinalCLCylOD: "",
    newFinalCLAxisOD: "",
    newAdd: "",
    newFinalSphOS: "",
    newFinalCylOS: "",
    newFinalAxisOS: "",
    newFinalCLSphOS: "",
    newFinalCLCylOS: "",
    newFinalCLAxisOS: "",
    user: "",
    description: "",
  });
  const [logTest, setLogTest] = useState({});

  const location = useLocation();
  data = location?.state?.data || {};
  // if (!data) return null;
  // console.log('data', data);
  const { user_id, uuid } = data;
  delete data["_id"];
  // delete data["user_id"];
  delete data["phone"];
  // delete data["uuid"];
  delete data["createdAt"];
  delete data["updatedAt"];
  delete data["__v"];
  delete data["email"];
  // let keysData = Object.keys(data);

  const ItemTable = (props) => (
    <div
      style={{
        fontSize: "12px",
        width: "145px",
        padding: "3px",
        margin: "2px 3px 2px 0",
        height: "40px",
        border: "1px solid #ccc",
        alignItems: "flex-start",
      }}
    >
      <strong>{props.name}:</strong>
      <br />
      {props.value}
    </div>
  );

  const formula3 = (_gender, _age) => {
    // console.log('params', _gender, _age);
    // console.log('formVar', formVar);
    //ADD POWER
    const gender = _gender;
    const age = _age;

    const AddPwr = FunctionAddPower(gender, age);
    // console.log('AddPwr', AddPwr);
    setFormVar({ ...formVar, AddPwr: AddPwr });
    return AddPwr;
  };

  const formula4 = (
    KnowSpec,
    KnowContact,
    OldSphOD,
    OldSphOS,
    OldCylOD,
    OldCylOS,
    Answer1a,
    Answer1b,
    CL_OldSphOD,
    CL_OldSphOS,
    CL_OldCylOD,
    CL_OldCylOS
  ) => {
    //Eye VALENCE

    const { EyeValenceOD, EyeValenceOS, EyeValenceSafety } = FunctionEyeValence(
      KnowSpec,
      KnowContact,
      OldSphOD,
      OldSphOS,
      OldCylOD,
      OldCylOS,
      Answer1a,
      Answer1b,
      CL_OldSphOD,
      CL_OldSphOS,
      CL_OldCylOD,
      CL_OldCylOS
    );

    // console.log('Eye VALENCE', EyeValenceOD, EyeValenceOS, EyeValenceSafety);
    setFormVar({ ...formVar, EyeValenceOD, EyeValenceOS, EyeValenceSafety });
  };

  const formula5 = (
    eyeValenceOD,
    eyeValenceOS,
    VAscOD,
    VAscOS,
    VAaOD,
    VAaOS,
    Age
  ) => {
    // SPE

    let SPEscOD = SPEMethods(eyeValenceOD, VAscOD, Age);
    let SPEscOS = SPEMethods(eyeValenceOS, VAscOS, Age);
    let SPEaOD = SPEMethods(eyeValenceOD, VAaOD, Age);
    let SPEaOS = SPEMethods(eyeValenceOS, VAaOS, Age);

    // console.log('SPE', SPEscOD, SPEscOS, SPEaOD, SPEaOS);
    setFormVar({ ...formVar, SPEscOD, SPEscOS, SPEaOD, SPEaOS });
  };

  const formula6 = (
    EyeValenceOD,
    EyeValenceOS,
    SPEscOD,
    SPEscOS,
    SPEaOD,
    SPEaOS,
    PrimeDrPOVOD,
    PrimeDrPOVOS
  ) => {
    //MEASURED Rx

    let measuredRxOD = FunctionMeasuredRx(
      Number(SPEscOD),
      Number(SPEaOD),
      PrimeDrPOVOD,
      EyeValenceOD
    );
    let measuredRxOS = FunctionMeasuredRx(
      Number(SPEscOS),
      Number(SPEaOS),
      PrimeDrPOVOS,
      EyeValenceOS
    );

    // console.log('MEASURED Rx',
    // measuredRxOD,
    // measuredRxOS
    // );

    setFormVar({
      ...formVar,
      MeasuredSphOD: (Math.round(measuredRxOD.Sphere * 4) / 4).toFixed(2),
      MeasuredSphOS: (Math.round(measuredRxOS.Sphere * 4) / 4).toFixed(2),
      MeasuredCylOD: (Math.round(measuredRxOD.Cylinder * 4) / 4).toFixed(2),
      MeasuredCylOS: (Math.round(measuredRxOS.Cylinder * 4) / 4).toFixed(2),
      MeasuredAxisOD: measuredRxOD.Axis,
      MeasuredAxisOS: measuredRxOS.Axis,
    });
  };
  const formula7 = () => {
    //Weighted FinalRx
    // console.log('formVar', formVar);
    const defaultOld = null;
    let VAccOD = formVar.VAccOD || defaultOld;
    let VAccOS = formVar.VAccOS || defaultOld;
    let OldSphOD = formVar.OldSphOD || defaultOld;
    let OldSphOS = formVar.OldSphOS || defaultOld;
    let OldCylOD = formVar.OldCylOD || defaultOld;
    let OldCylOS = formVar.OldCylOS || defaultOld;
    let OldAxisOD = formVar.OldAxisOD || defaultOld;
    let OldAxisOS = formVar.OldAxisOS || defaultOld;

    let MeasuredSphOD = Number(formVar.MeasuredSphOD);
    let MeasuredSphOS = Number(formVar.MeasuredSphOS);
    let MeasuredCylOD = Number(formVar.MeasuredCylOD);
    let MeasuredCylOS = Number(formVar.MeasuredCylOS);
    let MeasuredAxisOD = Number(formVar.MeasuredAxisOD);
    let MeasuredAxisOS = Number(formVar.MeasuredAxisOS);
    let HaveSpec = formVar.HaveSpec;

    let FinalRxOD = FunctionWeightedRx(
      VAccOD,
      OldSphOD,
      OldCylOD,
      OldAxisOD,
      MeasuredSphOD,
      MeasuredCylOD,
      MeasuredAxisOD,
      HaveSpec
    );
    let FinalRxOS = FunctionWeightedRx(
      VAccOS,
      OldSphOS,
      OldCylOS,
      OldAxisOS,
      MeasuredSphOS,
      MeasuredCylOS,
      MeasuredAxisOS,
      HaveSpec
    );

    let FinalCLAxisOD = Math.round(FinalRxOD.FinalAxis / 10) * 10; //rounds the contact lens Axis to the nearest 10 degrees
    let FinalCLAxisOS = Math.round(FinalRxOS.FinalAxis / 10) * 10;

    // console.log('Weighted FinalRxOD', FinalRxOD);
    // console.log('Weighted FinalRxOS', FinalRxOS);

    setFormVar({
      ...formVar,
      FinalSphOD: FinalRxOD.FinalSph,
      FinalCylOD: FinalRxOD.FinalCyl,
      FinalAxisOD: FinalRxOD.FinalAxis,
      FinalSphOS: FinalRxOS.FinalSph,
      FinalCylOS: FinalRxOS.FinalCyl,
      FinalAxisOS: FinalRxOS.FinalAxis,
      FinalCLAxisOD,
      FinalCLAxisOS,
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const uuid = queryParams.get("uuid") || data.uuid;
    axios
      .get(`${process.env.REACT_APP_API}/user/test-admin/${uuid}`, {
        withCredentials: true,
      })
      .then(function (res) {
        const data = res.data.test;
        setDataParam(res.data.test);

        delete data["_id"];
        delete data["phone"];
        delete data["createdAt"];
        delete data["updatedAt"];
        delete data["__v"];
        delete data["email"];
        delete data["FinalCLSphOD"];
        delete data["FinalCLCylOD"];
        delete data["FinalCLAxisOD"];
        delete data["FinalCLSphOS"];
        delete data["FinalCLCylOS"];
        // delete data["FinalCLAxisOS"];
        delete data["updateDate"];
        delete data["newFinalSphOD"];
        delete data["newFinalCylOD"];
        delete data["newFinalAxisOD"];
        delete data["newFinalCLSphOD"];
        delete data["newFinalCLCylOD"];
        delete data["newFinalCLAxisOD"];
        delete data["newAdd"];
        delete data["newFinalSphOS"];
        delete data["newFinalCylOS"];
        delete data["newFinalAxisOS"];
        delete data["newFinalCLSphOS"];
        delete data["newFinalCLCylOS"];
        delete data["newFinalCLAxisOS"];
        delete data["user"];
        delete data["description"];
        delete data["changeValues"];
        delete data["userApproved"];
        delete data["statusSpeciaist"];
        delete data["status"];
        delete data["specialistDescription"];
        delete data["text_title"];
        delete data["text_subhead"];
        delete data["text_glasses"];
        delete data["text_right_eye"];
        delete data["text_left_eye"];
        delete data["text_contacts"];
        delete data["text_demo"];
        delete data["text_sphere"];
        delete data["text_cylinder"];
        delete data["text_axis"];
        delete data["text_power"];
        delete data["text_subject"];
        delete data["text_feedback_button"];
        delete data["text_feedback_text"];
        delete data["emailSent"];

        setKeysData(Object.keys(data));
        setFormVar({ ...res.data.test });
        setLogTest({ logs: res.data.logs });
      })
      .catch((e) => {
        console.log("Error");
      });
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div
        class="tableDiv"
        style={{
          /*position: "fixed",*/ left: 0,
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "50%",
          width: "100%",
          alignItems: "flex-start",
          alignContent: "flex-start",
        }}
      >
        <div class="tableData" style={{ display: "flex", flexWrap: "wrap" }}>
          {keysData &&
            keysData.map(
              (item, i) =>
                item !== "user_id" &&
                item !== "uuid" && (
                  <ItemTable name={item} value={dataParam[item]} index={i} />
                )
            )}
        </div>
        <TestLogTable test={logTest?.logs} />
      </div>

      <Form
        style={{
          /*marginLeft: "50%",*/ borderLeft: "2px solid black",
          maxWidth: "50%",
          width: "100%",
        }}
      >
        <Form.Group style={{ margin: "50px 20px" }}>
          <h4>Test AddPwd (Formula3)</h4>
          <br />
          <label style={{ marginTop: "10px" }}>gender</label>
          <Form.Control
            type="text"
            placeholder="gender"
            value={formVar.gender}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, gender: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>age</label>
          <Form.Control
            type="text"
            placeholder="age"
            value={formVar.age}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, age: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <Button
            style={{
              marginLeft: "60px",
              width: "100px",
              height: "40px",
              margin: "10px 10px 10px 0",
            }}
            variant="primary"
            type="button"
            onClick={() => formula3(formVar.gender, formVar.age)}
          >
            Submit
          </Button>
          <div
            className="nav-view"
            style={{ borderTop: "1px solid black", padding: "10px" }}
          >
            result AddPwd:{" "}
            <strong style={{ fontSize: "20px" }}>{formVar.Add}</strong>
          </div>
        </Form.Group>
        <Form.Group style={{ margin: "50px 20px" }}>
          <h4>Eye VALENCE (Formula4)</h4>
          <label style={{ marginTop: "10px" }}>KnowSpec</label>
          <Form.Control
            type="text"
            placeholder="KnowSpec"
            value={formVar.KnowSpec}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, KnowSpec: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>KnowContact</label>
          <Form.Control
            type="text"
            placeholder="KnowContact"
            value={formVar.KnowContact}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, KnowContact: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldSphOD</label>
          <Form.Control
            type="text"
            placeholder="OldSphOD"
            value={formVar.OldSphOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldSphOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldSphOS</label>
          <Form.Control
            type="text"
            placeholder="OldSphOS"
            value={formVar.OldSphOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldSphOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldCylOD</label>
          <Form.Control
            type="text"
            placeholder="OldCylOD"
            value={formVar.OldCylOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldCylOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldCylOS</label>
          <Form.Control
            type="text"
            placeholder="OldCylOS"
            value={formVar.OldCylOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldCylOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>Answer1a</label>
          <Form.Control
            type="text"
            placeholder="Answer1a"
            value={formVar.Answer1a}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, Answer1a: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>Answer1b</label>
          <Form.Control
            type="text"
            placeholder="Answer1b"
            value={formVar.Answer1b}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, Answer1b: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>CL_OldSphOD</label>
          <Form.Control
            type="text"
            placeholder="CL_OldSphOD"
            value={formVar.CL_OldSphOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, CL_OldSphOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>CL_OldCylOD</label>
          <Form.Control
            type="text"
            placeholder="CL_OldCylOD"
            value={formVar.CL_OldCylOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, CL_OldCylOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>CL_OldSphOS</label>
          <Form.Control
            type="text"
            placeholder="CL_OldSphOS"
            value={formVar.CL_OldSphOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, CL_OldSphOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>CL_OldCylOS</label>
          <Form.Control
            type="text"
            placeholder="CL_OldCylOS"
            value={formVar.CL_OldCylOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, CL_OldCylOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <Button
            style={{
              marginLeft: "60px",
              width: "100px",
              height: "40px",
              margin: "10px 10px 10px 0",
            }}
            variant="primary"
            type="button"
            onClick={() =>
              formula4(
                formVar.KnowSpec,
                formVar.KnowContact,
                formVar.OldSphOD,
                formVar.OldSphOS,
                formVar.OldCylOD,
                formVar.OldCylOS,
                formVar.Answer1a,
                formVar.Answer1b,
                formVar.CL_OldSphOD,
                formVar.CL_OldSphOS,
                formVar.CL_OldCylOD,
                formVar.CL_OldCylOS
              )
            }
          >
            Submit
          </Button>
          <div
            className="nav-view"
            style={{ borderTop: "1px solid black", padding: "10px" }}
          >
            result EyeValenceOD:{" "}
            <strong style={{ fontSize: "20px" }}>{formVar.EyeValenceOD}</strong>{" "}
            <br />
            result EyeValenceOS:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.EyeValenceOS}
            </strong>{" "}
            <br />
            result EyeValenceSafety:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.EyeValenceSafety}
            </strong>{" "}
            <br />
          </div>
        </Form.Group>

        <Form.Group style={{ margin: "50px 20px" }}>
          <h4>SPE (Formula5)</h4>
          <br />
          <label style={{ marginTop: "10px" }}>eyeValenceOD</label>
          <Form.Control
            type="text"
            placeholder="eyeValenceOD"
            value={formVar.EyeValenceOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, EyeValenceOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>eyeValenceOS</label>
          <Form.Control
            type="text"
            placeholder="eyeValenceOS"
            value={formVar.EyeValenceOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, EyeValenceOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>VAscOD</label>
          <Form.Control
            type="text"
            placeholder="VAscOD"
            value={formVar.VAscOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, VAscOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>VAscOS</label>
          <Form.Control
            type="text"
            placeholder="VAscOS"
            value={formVar.VAscOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, VAscOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>VAaOD</label>
          <Form.Control
            type="text"
            placeholder="VAaOD"
            value={formVar.VAaOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, VAaOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>VAaOS</label>
          <Form.Control
            type="text"
            placeholder="VAaOS"
            value={formVar.VAaOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, VAaOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>age</label>
          <Form.Control
            type="text"
            placeholder="age"
            value={formVar.age}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, age: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <Button
            style={{
              marginLeft: "60px",
              width: "100px",
              height: "40px",
              margin: "10px 10px 10px 0",
            }}
            variant="primary"
            type="button"
            onClick={() =>
              formula5(
                formVar.EyeValenceOD,
                formVar.EyeValenceOD,
                formVar.VAscOD,
                formVar.VAscOS,
                formVar.VAaOD,
                formVar.VAaOS,
                formVar.age
              )
            }
          >
            Submit
          </Button>
          <div
            className="nav-view"
            style={{ borderTop: "1px solid black", padding: "10px" }}
          >
            result SPEscOD:{" "}
            <strong style={{ fontSize: "20px" }}>{formVar.SPEscOD}</strong>{" "}
            <br />
            result SPEscOS:{" "}
            <strong style={{ fontSize: "20px" }}>{formVar.SPEscOS}</strong>{" "}
            <br />
            result SPEaOD:{" "}
            <strong style={{ fontSize: "20px" }}>{formVar.SPEaOD}</strong>{" "}
            <br />
            result SPEaOS:{" "}
            <strong style={{ fontSize: "20px" }}>{formVar.SPEaOS}</strong>{" "}
            <br />
          </div>
        </Form.Group>

        <Form.Group style={{ margin: "50px 20px" }}>
          <h4>MEASURED Rx (Formula6)</h4>
          <br />
          <label style={{ marginTop: "10px" }}>EyeValenceOD</label>
          <Form.Control
            type="text"
            placeholder="EyeValenceOD"
            value={formVar.EyeValenceOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, EyeValenceOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>EyeValenceOS</label>
          <Form.Control
            type="text"
            placeholder="EyeValenceOS"
            value={formVar.EyeValenceOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, EyeValenceOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>SPEscOD</label>
          <Form.Control
            type="text"
            placeholder="SPEscOD"
            value={formVar.SPEscOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, SPEscOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>SPEscOS</label>
          <Form.Control
            type="text"
            placeholder="SPEscOS"
            value={formVar.SPEscOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, SPEscOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>SPEaOD</label>
          <Form.Control
            type="text"
            placeholder="SPEaOD"
            value={formVar.SPEaOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, SPEaOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>SPEaOS</label>
          <Form.Control
            type="text"
            placeholder="SPEaOS"
            value={formVar.SPEaOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, SPEaOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>PrimeDrPOVOD</label>
          <Form.Control
            type="text"
            placeholder="PrimeDrPOVOD"
            value={formVar.PrimeDrPOVOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, PrimeDrPOVOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>PrimeDrPOVOS</label>
          <Form.Control
            type="text"
            placeholder="PrimeDrPOVOS"
            value={formVar.PrimeDrPOVOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, PrimeDrPOVOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <Button
            style={{
              marginLeft: "60px",
              width: "100px",
              height: "40px",
              margin: "10px 10px 10px 0",
            }}
            variant="primary"
            type="button"
            onClick={() =>
              formula6(
                formVar.EyeValenceOD,
                formVar.EyeValenceOS,
                formVar.SPEscOD,
                formVar.SPEscOS,
                formVar.SPEaOD,
                formVar.SPEaOS,
                formVar.PrimeDrPOVOD,
                formVar.PrimeDrPOVOS
              )
            }
          >
            Submit
          </Button>
          <div
            className="nav-view"
            style={{ borderTop: "1px solid black", padding: "10px" }}
          >
            result MeasuredSphOD:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.MeasuredSphOD}
            </strong>{" "}
            <br />
            result MeasuredSphOS:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.MeasuredSphOS}
            </strong>{" "}
            <br />
            result MeasuredCylOD:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.MeasuredCylOD}
            </strong>{" "}
            <br />
            result MeasuredCylOS:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.MeasuredCylOS}
            </strong>{" "}
            <br />
            result MeasuredAxisOD:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.MeasuredAxisOD}
            </strong>{" "}
            <br />
            result MeasuredAxisOS:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.MeasuredAxisOS}
            </strong>{" "}
            <br />
          </div>
        </Form.Group>

        <Form.Group style={{ margin: "50px 20px" }}>
          <h4>Weighted FinalRx (Formula7)</h4>
          <br />
          <label style={{ marginTop: "10px" }}>VAccOD</label>
          <Form.Control
            type="text"
            placeholder="VAccOD"
            value={formVar.VAccOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, VAccOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>VAccOS</label>
          <Form.Control
            type="text"
            placeholder="VAccOS"
            value={formVar.VAccOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, VAccOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldSphOD</label>
          <Form.Control
            type="text"
            placeholder="OldSphOD"
            value={formVar.OldSphOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldSphOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldSphOS</label>
          <Form.Control
            type="text"
            placeholder="OldSphOS"
            value={formVar.OldSphOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldSphOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldCylOD</label>
          <Form.Control
            type="text"
            placeholder="OldCylOD"
            value={formVar.OldCylOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldCylOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldCylOS</label>
          <Form.Control
            type="text"
            placeholder="OldCylOS"
            value={formVar.OldCylOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldCylOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldAxisOD</label>
          <Form.Control
            type="text"
            placeholder="OldAxisOD"
            value={formVar.OldAxisOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldAxisOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>OldAxisOS</label>
          <Form.Control
            type="text"
            placeholder="OldAxisOS"
            value={formVar.OldAxisOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, OldAxisOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>MeasuredSphOD</label>
          <Form.Control
            type="text"
            placeholder="MeasuredSphOD"
            value={formVar.MeasuredSphOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, MeasuredSphOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>MeasuredSphOS</label>
          <Form.Control
            type="text"
            placeholder="MeasuredSphOS"
            value={formVar.MeasuredSphOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, MeasuredSphOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>MeasuredCylOD</label>
          <Form.Control
            type="text"
            placeholder="MeasuredCylOD"
            value={formVar.MeasuredCylOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, MeasuredCylOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>MeasuredCylOS</label>
          <Form.Control
            type="text"
            placeholder="MeasuredCylOS"
            value={formVar.MeasuredCylOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, MeasuredCylOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>MeasuredAxisOD</label>
          <Form.Control
            type="text"
            placeholder="MeasuredAxisOD"
            value={formVar.MeasuredAxisOD}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, MeasuredAxisOD: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>MeasuredAxisOS</label>
          <Form.Control
            type="text"
            placeholder="MeasuredAxisOS"
            value={formVar.MeasuredAxisOS}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, MeasuredAxisOS: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <label style={{ marginTop: "10px" }}>HaveSpec</label>
          <Form.Control
            type="text"
            placeholder="HaveSpec"
            value={formVar.HaveSpec}
            size={"sm"}
            onChange={(v) => {
              setFormVar({ ...formVar, HaveSpec: v.target.value });
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <Button
            style={{
              marginLeft: "60px",
              width: "100px",
              height: "40px",
              margin: "10px 10px 10px 0",
            }}
            variant="primary"
            type="button"
            onClick={() => formula7()}
          >
            Submit
          </Button>
          <div
            className="nav-view"
            style={{ borderTop: "1px solid black", padding: "10px" }}
          >
            result FinalSphOD:{" "}
            <strong style={{ fontSize: "20px" }}>{formVar.FinalSphOD}</strong>{" "}
            <br />
            result FinalCylOD:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.FinalCylOD}
            </strong>{" "}
            <br />
            result FinalAxisOD:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.FinalAxisOD}
            </strong>{" "}
            <br />
            result FinalCLAxisOD:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.FinalAxisOD}
            </strong>{" "}
            <br />
            result FinalSphOS:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.FinalSphOS}
            </strong>{" "}
            <br />
            result FinalCylOS:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.FinalCylOS}
            </strong>{" "}
            <br />
            result FinalAxisOS:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.FinalAxisOS}
            </strong>{" "}
            <br />
            result FinalCLAxisOS:{" "}
            <strong style={{ fontSize: "20px" }}>
              {formVar.FinalCLAxisOS}
            </strong>{" "}
            <br />
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}
