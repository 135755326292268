import { useHistory } from "react-router-dom";
import React from "react";
import "./styles.css";
import { useTranslation } from 'react-i18next';
import TestWrapper from "../components/TestWrapper";

export default function HaveGlasses() {

    const { t } = useTranslation();

	const qsName = "havespec"; /*1. They know their glasses RX 
                               2. The know their glasses AND CL RX 
                               3. They don't know their glasses RX */

	const questionText = t("Do you have your old glasses with you?");
	const answerText1 = t("Yes");
	const answerText2 = t("No");

	const PATH_YES = "/wearing";
	const PATH_NO = "/wearing";

	// if(ally == "no" && brad == "no"){
	//     history.push({
	//         pathname: '/placecard',
	//     });
	// }

	const history = useHistory();

	const buttonAction = (qsName, answer, path) => {
		if (answer === answerText1) {
			//what happens if answer2 is chosen
			localStorage.setItem("HaveSpec", "yes");
		}
		if (answer === answerText2) {
			//what happens if answer2 is chosen
			localStorage.setItem("HaveSpec", "no");
		}
		history.push({
			pathname: path,
		});
	};

	return (
		<TestWrapper image={`images/test/010.jpg`}>
		<h2>{questionText}</h2>

		<div className="multiple-buttons">
			<div
				onClick={() => {
					buttonAction(qsName, answerText1, PATH_YES);
				}}
				className="button-default"
			>
				{answerText1}
			</div>

			<div
				onClick={() => {
					buttonAction(qsName, answerText2, PATH_NO);
				}}
				className="button-default"
			>
				<div>{answerText2}</div>
			</div>
		</div>
	</TestWrapper>
	);
}
