import axios from "axios";
import { URL_ROUTE_USER } from "../utils/constants";
import DynamicQuestion from "../components/Test/DynamicQuestion.map";
import { calculatePrescription } from "../components/Test/DynamicQuestion.script";

export const storageTest = async (token, data) => {
  await localStorage.setItem(`test-${token}`, JSON.stringify(data));
};

const get = async (token) => {
  let test = localStorage.getItem(`test-${token}`);

  if (!test) {
    // test not found, get info on the backend
    const resp = await axios.get(`${URL_ROUTE_USER}/getTest?token=${token}`, {
      withCredentials: true,
    });
    await storageTest(resp.data.token, resp.data);
    return resp.data;
  }

  return JSON.parse(test);
};

const create = async () => {
  const resp = await axios.get(`${URL_ROUTE_USER}/create`, {
    withCredentials: true,
  });
  const token = resp.data.token;
  const test = {
    token,
    uuid: resp.data.uuid,
  };
  storageTest(token, test);
  return test;
};

// used by mobile, just read current workflow
const readWorkflow = (test) => {
  return DynamicQuestion[test.workflow];
};

const loadWorkflow = (test) => {
  if (!test.workflow) {
    test.workflow = Object.keys(DynamicQuestion)[0]; // get first step
  }

  const workflow = DynamicQuestion[test.workflow];
  // update the test info
  if (workflow.load) {
    workflow.load(test);
  } else {
    if (!test.steps) {
      test.steps = {};
    }
    // load initial steps info
    test.steps[test.workflow] = { state: {}, answers: [] };
  }

  return workflow;
};

const saveWorkflow = (test, lastWorkflow) => {
  const payload = { workflow: test.workflow };

  // check if last workflow need to storage something on database
  const workflow = DynamicQuestion[lastWorkflow];
  // if step is undefined, this lastStep was skipped
  if (workflow.storageOnBackend && test.steps[lastWorkflow]) {
    const { answers, result } = test.steps[lastWorkflow];

    // add more info on payload
    payload.step = lastWorkflow;
    payload.data = { answers, result };
  }

  axios.post(`${URL_ROUTE_USER}/workflow?token=${test.token}`, payload, {
    withCredentials: true,
    headers: { "Content-type": "application/json; charset=UTF-8" },
  });
};

const searchNextValidQuestion = (test) => {
  const steps = Object.keys(DynamicQuestion);
  const currentIndex = steps.indexOf(test.workflow);
  const nextIndex = currentIndex + 1;

  if (nextIndex >= steps.length) {
    // ignore this moveNext call if is missing new steps
    return;
  }

  const step = Object.keys(DynamicQuestion)[nextIndex];
  test.workflow = step;

  // if has conditional, call this function
  if (DynamicQuestion[step].conditional) {
    const validateStep = DynamicQuestion[step].conditional(test);

    if (!validateStep) {
      // move to next index and move to next
      return searchNextValidQuestion(test);
    }
  }
};

const moveToNextQuestion = async (test) => {
  const lastWorkflow = test.workflow;

  searchNextValidQuestion(test);

  // populate workflow info on backend
  saveWorkflow(test, lastWorkflow);

  await storageTest(test.token, test);
};

const finishTest = async (test, textPrescription) => {
  // if already finalized, just return
  if (test.finalized === true) {
    return;
  }

  // TODO: move logic to calculate the prescription on the backend
  const prescription = {
    ...calculatePrescription(test),
    ...textPrescription,
  };

  const result = await axios.post(
    `${URL_ROUTE_USER}/finish?token=${test.token}`,
    prescription,
    {
      withCredentials: true,
      headers: { "Content-type": "application/json; charset=UTF-8" },
    }
  );

  // clear this test local storage
  localStorage.removeItem(`test-${test.token}`);

  return result;
};

const getTestLogs = (test) => {
  const logs = [];
  for (const step in test.steps) {
    const workflow = DynamicQuestion[step];

    if (!workflow || !workflow.log) {
      // workflow not fount of without a log function, ignore
      continue;
    }

    logs.push(workflow.log(test.steps[step]));
  }
  return logs;
};

export const useNewTest = () => {
  return {
    get,
    create,
    moveToNextQuestion,
    loadWorkflow,
    readWorkflow,
    finishTest,
    getTestLogs,
  };
};
