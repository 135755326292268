import "../styles.css";
import { useTranslation } from "react-i18next";

export default function MobileThankyou() {
  const { t } = useTranslation();

  return (
    <div className="end-test">
      <h1>{t("End of Test")}</h1>
      <p>{t("Please close this page and continue on PC.")}</p>
    </div>
  );
}
