import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles.css";
import { useTranslation } from "react-i18next";
import { c_button } from "../../../sketches/c_button";

export default function Mobile4Circles({ answer }) {
  const { t } = useTranslation();
  const size = 80;

  return (
    <div className="mobile-tests">
      <div className="main-view">
        <div className="main-view-inset">
          <h2>{t("Select what you see")}</h2>
          <div className="main-view-inset-wrapper">
            <div className="centerv">
              <span
                onClick={() => {
                  answer(2);
                }}
              >
                <ReactP5Wrapper sketch={c_button} i={90} r={size} />
              </span>

              <div className="centerh">
                <span
                  style={{ paddingRight: "110px" }}
                  onClick={() => {
                    answer(1);
                  }}
                >
                  <ReactP5Wrapper sketch={c_button} i={0} r={size} />
                </span>
                <span
                  onClick={() => {
                    answer(3);
                  }}
                >
                  <ReactP5Wrapper sketch={c_button} i={180} r={size} />
                </span>
              </div>
              <span
                onClick={() => {
                  answer(4);
                }}
              >
                <ReactP5Wrapper sketch={c_button} i={270} r={size} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-nav">
        <div className="button-default" onClick={() => answer(5)}>
          {t("Can't Tell")}
        </div>
      </div>
    </div>
  );
}
