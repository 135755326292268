import "../styles.css";
import { useTranslation } from "react-i18next";
import MobileCoverEye from "./MobileCoverEye";
import cartoonCoverRightEye from "../../../assets/img/cartoonCoverRightEye.svg";

export default function MobileCoverRight({ test, answer }) {
  const { t } = useTranslation();

  return (
    <MobileCoverEye
      image={cartoonCoverRightEye}
      head={t("Stage Complete!")}
      subhead={t("Cover your RIGHT eye then press 'Next Stage'")}
      answer={answer}
    />
  );
}
