import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWrapper from "../../TestWrapper";
import { Form } from "react-bootstrap";
import { useState } from "react";

export default function DesktopAge({ answer }) {
  const { t } = useTranslation();
  const [BYear, setBYear] = useState("");
  const [BMonth, setBMonth] = useState("");
  const [BDay, setBDay] = useState("");

  const buttonAction = (BYear, BMonth, BDay) => {
    let today = new Date().getFullYear();
    let age = today - BYear;
    if (BMonth.length == 1) {
      BMonth = "0" + BMonth;
    }
    if (BDay.length == 1) {
      BDay = "0" + BDay;
    }

    if (
      BYear.length == 4 &&
      Number(BYear) > 0 &&
      BMonth.length == 2 &&
      Number(BMonth) > 0 &&
      BDay.length == 2 &&
      Number(BDay) > 0
    ) {
      let dob = BYear + "-" + BMonth + "-" + BDay;

      answer({ dob, age });
    } else {
      alert(
        t(
          "there must be four (4) digits for year, two (2) for month, and two (2) for day"
        )
      );
    }
  };

  return (
    <TestWrapper image={`../images/test/002.jpg`}>
      <h2>{t("Type your birth year, month and day")}</h2>

      <div className="form-view">
        <Form>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder={t("Birth year (yyyy)")}
              size={"lg"}
              min={1900}
              max={2022}
              onChange={(v) => {
                setBYear(v.target.value);
              }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Form.Group>
        </Form>

        <Form>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder={t("Birth Month (mm)")}
              size={"lg"}
              min={1}
              onChange={(v) => {
                setBMonth(v.target.value);
              }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Form.Group>
        </Form>

        <Form>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder={t("Birth Day (dd)")}
              size={"lg"}
              min={0}
              max={31}
              onChange={(v) => {
                setBDay(v.target.value);
              }}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Form.Group>
        </Form>
      </div>

      <div className="nav-view">
        <div
          onClick={() => buttonAction(BYear, BMonth, BDay)}
          className="nav-button"
        >
          <div>{t("Submit")}</div>
        </div>
      </div>
    </TestWrapper>
  );
}
