import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import cartoon2 from "../../../assets/img/cartoon2.svg";

export default function DesktopPlaceObject() {
  const { t } = useTranslation();

  return (
    <TestWaitScreen
      sound="mpage13"
      image={cartoon2}
      head={t("Test in process")}
      subhead={t("Waiting for action on mobile device...")}
    />
  );
}
