import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import cartoonBlank from "../../../assets/img/cartoonBLANK.jpeg";

export default function DesktopNearOrFarObjects() {
  const { t } = useTranslation();

  return (
    <TestWaitScreen
      sound="mpage14test1a"
      image={cartoonBlank}
      head={t("Test in process")}
      subhead={t("Waiting for action on mobile device...")}
    />
  );
}
