
export const LandingCard = ({title, subtitle, image}) => {
   return (
      <div className="article-card">
         <div class="card-image-wrapper">
            <div className="card-image" style={{ backgroundImage: `url(${image})` }}/>
         </div>
         <div className="text-card-wrapper">
            <h5>{title}</h5>
            <p>{subtitle}</p>
         </div>
      </div>
   );
};
