import { useTranslation } from "react-i18next";

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <footer>
        <div className="content">
          <div className="logo">
            <img src="images/logo.png" alt="" />
          </div>
          <nav>
            {/* <a href={`${QR_DOMAIN}#`}>{t("Terms of Use")}</a> */}
            {i18n.language === "en" ? (
              <a href="privacy-policy">{t("Privacy Policy")}</a>
            ) : (
              <a href="privacy-policy-br">{t("Privacy Policy")}</a>
            )}
          </nav>

          <div className="options">
            <span>© {t("All rights reserved")}.</span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
