import "../styles.css";
import { useTranslation } from "react-i18next";
import nearFar from "../../../assets/img/nearfar.jpg";

export default function MobileWhichDevice({ answer }) {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <div className="mobile-view">
          <h1>{t("On which device is the picture easier for you to see?")}</h1>
          <div className="image-view">
            <img src={nearFar} alt="" style={{ width: "90%" }} />
          </div>
          <div className="nav-buttons">
            <div className="nav-button" onClick={() => answer("near")}>
              {t("Mobile")}
            </div>
            <div className="nav-button" onClick={() => answer("far")}>
              {t("Computer")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
