import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { QRCode } from "react-qr-svg";
import "./styles.css";
import {
	PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK,
	PAGE_STATUS_WAITING_FOR_CONNECTION,
	ROUTES_PC_CONNECTION,
	ROUTES_PC_STARTED_SESSION,
	QR_DOMAIN,
} from "../utils/constants";
import { useRealtime } from '../hooks/useRealtime';
import { useTranslation } from "react-i18next";
import { useTest } from "../hooks/useTest";

const QR_BACKGROUND_COLOR = "#FFF";
const QR_FOREGROUND_COLOR = "#000";

function Page11QR({ history }) {
	const [state, setState] = useState({ link: "" });
    const { t, i18n } = useTranslation();
	const realtime = useRealtime("desktop");
	const { create, processMobileMessage } = useTest();

	useEffect(() => {
		const createTest = async () => {
			const test = await create();
			setState({ link: `${QR_DOMAIN}/mconnection?token=${test.token}&lang=${i18n.language}`});
			realtime.subscribe(test.token, async () => {
				realtime.listener(test.token, 'update-message', async (message) => {
					await processMobileMessage(message, (data) => {
						if (message.action === 'openQRCode') {
							realtime.trigger(test.token, 'm-connection-opened', test);
						}
						pollingStatus(data)
					});
				});
			});
		};
		createTest();
	}, []);

	const pollingStatus = (data) => {
		const { pageStatus, token } = data;
		if (
			pageStatus === PAGE_STATUS_WAITING_FOR_CONNECTION &&
			window.location.pathname === ROUTES_PC_CONNECTION
		) {
			return;
		} else if (pageStatus === PAGE_STATUS_PHONE_CONNECTED_STEPPING_BACK) {
			realtime.unbind(token);
			history.push({
				pathname: ROUTES_PC_STARTED_SESSION,
				search: "?token=" + token,
			});
		}
	};

	const questionText =
		t("Scan QR or enter the link below on your mobile device to continue");
	return (
		<div className="qrcode-view">
			<h2>{questionText}</h2>
			<div className="qr-code-wrapper">
				<QRCode
					bgColor={QR_BACKGROUND_COLOR}
					fgColor={QR_FOREGROUND_COLOR}
					level="Q"
					style={{ width: 450 }}
					value={state.link}
				/>
				<a href={state.link}>{state.link}</a>
			</div>
		</div>
	);
}

// class Page11QR extends React.Component {
// 	state = {
// 		link: "",
// 	};

// 	//todo: should create user profile in previous page to get consistent token for refreshing.
// 	componentWillMount() {
// 		console.log(URL_ROUTE_USER);
// 	}

// 	render() {
// 		return;
// 	}
// }

export default withRouter(Page11QR);
