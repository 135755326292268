import "../styles.css";
import { useTranslation } from "react-i18next";
import { QRCode } from "react-qr-svg";
import { QR_DOMAIN } from "../../../utils/constants";

const QR_BACKGROUND_COLOR = "#FFF";
const QR_FOREGROUND_COLOR = "#000";

export default function DesktopConnection({ test }) {
    const { t, i18n } = useTranslation();
	const link = `${QR_DOMAIN}/test/mconnection?token=${test.token}&lang=${i18n.language}`
	const questionText = t("Scan QR or enter the link below on your mobile device to continue");

	return (
		<div className="qrcode-view">
			<h2>{questionText}</h2>
			<div className="qr-code-wrapper">
				<QRCode
					bgColor={QR_BACKGROUND_COLOR}
					fgColor={QR_FOREGROUND_COLOR}
					level="Q"
					style={{ width: 450 }}
					value={link}
				/>
				<a href={link}>{link}</a>
			</div>
		</div>
	);
}
