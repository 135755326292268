import "../styles.css";
import { useTranslation } from "react-i18next";

export default function MobilePlaceObject({ answer }) {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <div className="mobile-view">
          <h1>{t("Place an object at your toes to mark your spot")}</h1>
          <h2>
            {t(
              "Confirm that you are now 10 feet from your computer screen with your mobile device"
            )}
          </h2>
          <div className="nav-view">
            <div className="nav-button" onClick={() => answer({})}>
              {t("Confirm")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
