import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const useSpecInfoOptions = (options = [], inputName = '', setOption) => {
  const { t } = useTranslation();

  return options.map((option) => {
    return (
      <Form.Group className="form-check" key={option}>
        <Form.Check
          onChange={(e) => setOption(e.target.value)}
          inline
          className="form-check-input"
          type="radio"
          name={inputName}
          value={option}
          id={option}
        />
        <Form.Label className="form-check-label" htmlFor={option}>
          {t(`specs_info_form.options.${option}`)}
        </Form.Label>
      </Form.Group>
    );
  });
};