import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import cartoonCoverRightEye from "../../../assets/img/cartoonCoverRightEye.svg";

export default function DesktopCoverRight() {
  const { t } = useTranslation();

  return (
    <TestWaitScreen
      sound="mpage15right"
      image={cartoonCoverRightEye}
      head={t("Stage Complete!")}
      subhead={t("Cover your RIGHT eye then press 'Next Stage'")}
    />
  );
}
